import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IconButton } from '@material-ui/core';

const Arrow = ({direction, clickFunction}) => {
    const icon = direction==='left'? 
    (<IconButton style={{color:'white'}}>
        <ArrowBackIosIcon fontSize="default"/>
    </IconButton> ) : (
        <IconButton style={{color:'white'}}>
            <ArrowForwardIosIcon fontSize="default"/>
        </IconButton>
    )
    return (
        <div onClick={clickFunction}>
            {icon}           
        </div>
    )
}

export default Arrow
