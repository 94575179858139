import React, { useContext } from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid, Card, CardContent, Typography, CardHeader } from '@material-ui/core';
import useStyles from './styles';
import { CommercialDataContext } from '../../Contexts/commercialDataContext';
import TotalSalesChart from "../../Charts/TotalSalesChart";
import { COLORS } from "../../Constants";
import AverageRechargeCurve from "../../Charts/RechargeCurve";

const CommercialData = () => {

  const { totalSalesData,
    monthlyTransactionData,
    totalSalesDataStatus,
    monthlyTransactionDataStatus,
    todayTransactionQuery,
    yesterdayTransactionQuery,
    currentMonthTransactionQuery,
    lastMonthTransactionsQuery,
    lastTransactionQuery
  } = useContext(CommercialDataContext);

  const skeleton = (
    <>
      <Skeleton variant='text' width="100%" />
      <Skeleton variant='text' width="70%" />
      <Skeleton variant='circle' width={40} height={40} />
      <Skeleton variant='rect' width={400} height={200} />
    </>
  );
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container
        spacing={4}
        style={{ background: "#41425D", padding: 10, borderRadius: 10 }}>
        <Grid item xs={12} lg={6} style={{ height: 'auto' }}>
          {(monthlyTransactionDataStatus === 'loading' || totalSalesDataStatus === 'loading') ? skeleton : (
            <Card style={{ background: COLORS.CARD_BACKGROUND }}>
              <CardContent>
                <TotalSalesChart salesData={monthlyTransactionData} totalSale={totalSalesData.totalSale} />
              </CardContent>
            </Card>
          )
          }
        </Grid>
        <Grid item xs={12} lg={6} style={{ height: 'auto' }}>
          {(monthlyTransactionDataStatus === 'loading' || totalSalesDataStatus === 'loading') ? skeleton : (
            <Card style={{ background: COLORS.CARD_BACKGROUND }}>
              <CardContent>
                <AverageRechargeCurve salesData={monthlyTransactionData} totalSalesData={totalSalesData} />
              </CardContent>
            </Card>
          )
          }
        </Grid>

        <Grid item xs={12} sm={6} lg={3} >
          {todayTransactionQuery.status === "loading" ? skeleton : (<Card className={classes.card}>
            <CardHeader
              classes={{ subheader: classes.subheader }}
              subheader="Today's Transactions"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {parseFloat(todayTransactionQuery.data.totalSale.toFixed(2)).toLocaleString()}{" "}
                <span style={{ fontSize: 14, font: "Helvetica" }}>LRD</span>
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {parseFloat(todayTransactionQuery.data.rechargeCount.toFixed(0)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>transactions</span>
              </Typography>
            </CardContent>
          </Card>)}
        </Grid>

        <Grid item xs={12} sm={6} lg={3} >
          {yesterdayTransactionQuery.status === "loading" ? skeleton : (<Card className={classes.card}>
            <CardHeader
              classes={{ subheader: classes.subheader }}
              subheader="Yesterday's Transactions"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {parseFloat(yesterdayTransactionQuery.data.totalSale.toFixed(2)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>LRD</span>
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {parseFloat(yesterdayTransactionQuery.data.rechargeCount.toFixed(0)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>transactions</span>
              </Typography>
            </CardContent>
          </Card>)}
        </Grid>

        <Grid item xs={12} sm={6} lg={3} >
          {currentMonthTransactionQuery.status === "loading" ? skeleton : (<Card className={classes.card}>
            <CardHeader
              classes={{ subheader: classes.subheader }}
              subheader="This Month's Transactions"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {parseFloat(currentMonthTransactionQuery.data.totalSale.toFixed(2)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>LRD</span>
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {parseFloat(currentMonthTransactionQuery.data.rechargeCount.toFixed(0)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>transactions</span>
              </Typography>
            </CardContent>
          </Card>)}
        </Grid>
        <Grid item xs={12} sm={6} lg={3} >
          {lastMonthTransactionsQuery.status === "loading" ? skeleton : (<Card className={classes.card}>
            <CardHeader
              classes={{ subheader: classes.subheader }}
              subheader="Last Month's Transactions"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {parseFloat(lastMonthTransactionsQuery.data.totalSale.toFixed(2)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>LRD</span>
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {parseFloat(lastMonthTransactionsQuery.data.rechargeCount.toFixed(0)).toLocaleString()}{" "}
                <span style={{ fontSize: 14 }}>transactions</span>
              </Typography>
            </CardContent>
          </Card>)}
        </Grid>
        <Grid item xs={12} sm={6} lg={4} >
          {lastTransactionQuery.status === "loading" ? skeleton : (<Card className={classes.card}>
            <CardHeader
              classes={{ subheader: classes.subheader }}
              subheader="Last Transaction"
            />
            <CardContent>
              <Typography variant="h5" component="h2">
                {`${lastTransactionQuery.data.to}`}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {`Meter Serial: ${lastTransactionQuery.data.meterSerial}`}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {`Amount: ${parseFloat(lastTransactionQuery.data.amount.toFixed(2)).toLocaleString()}`}{" "}
                <span style={{ fontSize: 14 }}>LRD</span>
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {`Time: ${new Date(lastTransactionQuery.data.created._seconds*1000)}`}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                {`User: ${lastTransactionQuery.data.user}`}
              </Typography>
            </CardContent>
          </Card>)}
        </Grid>


      </Grid>
    </div>
  );
};

export default CommercialData;
