import React from "react";
import Chart from "react-apexcharts";

const LoadChart2 = ({ load, label }) => {
  const series = [load];
  const options = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#5c6bc0"],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            show: true,
            color: "white",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#00BAEC"],
        stops: [0, 100],
      },
    },
    labels: [label],
  };

  return (
    <div style={{ marginTop: 18 }}>
      <Chart options={options} series={series} type="radialBar" />
    </div>
  );
};
export default LoadChart2;
