import React from 'react'

import ReactApexChart from "react-apexcharts";
import { sumBy, chain } from 'lodash';
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const YearlySalesChart = ({ year, yearlySales, rate }) => {
  
  const sales = chain(yearlySales)
                  .map(ys=>{
                    const {month, ...sale}= ys;
                    return {month:parseInt(month), ...sale};
                  })
                  .orderBy(['month'], ['asc']).value();
  const salesTotal = sumBy(sales, s=>s.totalSale)
  const series = [{ name: "Sales", data: sales.map(s => parseFloat((s.totalSale/rate).toFixed(2))) }];
  const options = {
    chart: {
      type: 'area',
      foreColor: "#ccc",
      zoom: {
        enabled: false
      }
    },
    colors: ["#00BAEC"],
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0
      }
    },

    markers: {
      size: 2,
      colors: ["#000524"],
      strokeColor: "#00BAEC",
      strokeWidth: 1
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: `Total: ${formatter.format(salesTotal/rate)}`,
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      }
    },
    subtitle: {
      text: `${year.year} Sales`,
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      }
    },
    stroke: {
      curve: 'smooth',
      width: 1
    },
    tooltip: {
      theme: "light"
    },
    labels: sales.map(ge => `${ge.month}/${ge.year}`),
    yaxis: {
      show: true
    },
  }

  return (
    <div>
      <ReactApexChart height={400} options={options} series={series} type="area" />
    </div>
  )
}

export default YearlySalesChart
