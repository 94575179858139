import React, {createContext} from 'react';
import {useQuery} from 'react-query';
import API from '../api/API';
import {orderBy} from 'lodash';

export const CommercialDataContext = createContext();

const fetchTotalSales = async (key)=>{
    const result = await API.get('/total-sales');
    return result.data; 
}

const fetchMonthlyTransactions = async (key)=>{
    const result = await API.get('/monthly-transactions');
    const data = result.data;
    const salesData = data.map(d=>{
        return {year:parseInt(d.year), month:parseInt(d.month), totalSale:parseFloat(d.totalSale.toFixed(2)), rechargeCount:d.rechargeCount};
    });
    const sales = orderBy(salesData, ['year', 'month'], ['asc', 'asc']);
    return sales
}
const fetchTodayTransaction = async (key) =>{
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const result = await API.get(`/daily-transaction-total/${month+1}/${day}/${year}`);
    const data = result.data.hasOwnProperty("totalSale")? result.data :  {totalSale:0, rechargeCount:0};
    return data;
}
const fetchYesterdayTransaction = async (key) =>{
    const yesterday =new Date(Date.now() - 86400000);
    const month = yesterday.getMonth();
    const day = yesterday.getDate();
    const year = yesterday.getFullYear();
    const result = await API.get(`/daily-transaction-total/${month+1}/${day}/${year}`);
    const data = result.data.hasOwnProperty("totalSale")? result.data :  {totalSale:0, rechargeCount:0};
    return data;
}

const fetchCurrentMonthlyTransaction = async (key) =>{
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const result = await API.get(`/monthly-transaction/${month+1}/${year}`);
    const data = result.data.hasOwnProperty("totalSale")? result.data :  {totalSale:0, rechargeCount:0};
    return data;
}

const fetchLastMonthlyTransaction = async (key) =>{
    const currentDate = new Date();
    const currentPeriod = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastPeriod = new Date(currentPeriod.setMonth(currentPeriod.getMonth()-1));
    const month = lastPeriod.getMonth();
    const year = lastPeriod.getFullYear();
    const result = await API.get(`/monthly-transaction/${month+1}/${year}`);
    const data = result.data.hasOwnProperty("totalSale")? result.data :  {totalSale:0, rechargeCount:0};
    return data;
}
const fetchLastTransaction = async (key)=>{
    const result  = await API.get('/last-transaction');
    return result.data;
}

export const CommercialDataProvider = ({children}) => {
    const totalSalesDataQuery = useQuery('total-sales', fetchTotalSales);
    const monthlyTransactionDataQuery = useQuery('monthly-transactions', fetchMonthlyTransactions);
    const todayTransactionQuery = useQuery('today-transaction', fetchTodayTransaction);
    const yesterdayTransactionQuery = useQuery('yesterday-transaction', fetchYesterdayTransaction, { refetchOnWindowFocus: false });
    const currentMonthTransactionQuery = useQuery('current-month-transaction', fetchCurrentMonthlyTransaction);
    const lastMonthTransactionsQuery = useQuery('last-month-transaction', fetchLastMonthlyTransaction, { refetchOnWindowFocus: false });
    const lastTransactionQuery = useQuery('last-transaction', fetchLastTransaction);
    return (
        <CommercialDataContext.Provider value ={{
            totalSalesData: totalSalesDataQuery.data,
            totalSalesDataStatus: totalSalesDataQuery.status,
            monthlyTransactionData: monthlyTransactionDataQuery.data,
            monthlyTransactionDataStatus: monthlyTransactionDataQuery.status,
            todayTransactionQuery,
            yesterdayTransactionQuery,
            currentMonthTransactionQuery,
            lastMonthTransactionsQuery,
            lastTransactionQuery
        }}>
            {children}
        </CommercialDataContext.Provider>
    )
}
