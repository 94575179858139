import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const powerAPI = axios.create({
  baseURL: `${process.env.REACT_APP_POWER_API_URL}/api/v0`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const customerAPI = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMERS_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
