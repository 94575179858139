import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import useStyles from "./styles";
import { GeneratorDataContext } from "../../Contexts/generatorDataContext";
import { Skeleton } from "@material-ui/lab";
import { percentPeakLoad2, calculateLoadFactor2 } from "./energyUsageService";
import LoadChart2 from "../../Charts/LoadChart2";
import LoadProfileChart from "../../Charts/LoadProfileChart";

const formatFigure = (figure) => {
  if (!figure) return `0 `;
  //include commas in figure
  return `${parseFloat(figure.toFixed(1)).toLocaleString()}`;
};

//return period as Monthname Year
const getMonthYear = (period) => {
  const today = new Date();
  if (!period)
    return `${today.toLocaleString("default", {
      month: "long",
      year: "numeric",
    })}`;
  const [year, month] = period.split("-");
  const date = new Date(parseInt(year), parseInt(month) - 1, 1);
  return `${date.toLocaleString("default", {
    month: "long",
    year: "numeric",
  })}`;
};

const formatPeriod = (period) => {
  const { year, month } = period;
  //padd month with 0 if less than 10
  const monthStr = month < 10 ? `0${month}` : month;
  return `${year}-${monthStr}`;
};

const EnergyUsage = () => {
  const { setPeriod, period, uniqueMonthsQuery, monthlyTotalizerDataQuery } =
    useContext(GeneratorDataContext);

  const [periodValue, setPeriodValue] = useState(formatPeriod(period));

  const handleListItemClick = (event) => {
    const period = event.target.value;
    const year = parseInt(period.split("-")[0]);
    const month = parseInt(period.split("-")[1]);
    setPeriodValue(period);
    setPeriod({ year, month });
  };

  const classes = useStyles();
  const skeleton = (
    <>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="70%" />
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="rect" width={400} height={200} />
    </>
  );
  return (
    <div
      className={classes.root}
      style={{ minWidth: "100%", minHeight: "100%" }}
    >
      <Grid
        container
        spacing={4}
        justify="flex-start"
        alignItems="center"
        style={{ background: "#41425D", padding: 8, borderRadius: 8 }}
      >
        <Grid item xs={12}>
          {uniqueMonthsQuery.status === "loading" ? (
            <Skeleton variant="text" width="50%" />
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                style={{ color: "white" }}
                htmlFor="outlined-period-native-simple"
              >
                Select Period
              </InputLabel>
              <Select
                style={{ color: "white" }}
                labelId="outlined-period-native-simple-label"
                id="outlined-period-native-simple"
                value={periodValue}
                onChange={handleListItemClick}
                label="Select Period"
              >
                {uniqueMonthsQuery?.data?.data?.map((period) => {
                  return (
                    <MenuItem key={`${period}`} value={period}>
                      {getMonthYear(period)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          {monthlyTotalizerDataQuery.status === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.card}>
              <CardHeader
                classes={{ subheader: classes.subheader }}
                subheader="Energy Generated"
              />
              <CardContent>
                <Typography variant="h5" component="h2">
                  {formatFigure(
                    monthlyTotalizerDataQuery?.data?.data?.totalkWh
                  )}{" "}
                  <span style={{ fontSize: 14 }}>kWh</span>
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          {monthlyTotalizerDataQuery.status === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.card}>
              <CardHeader
                classes={{ subheader: classes.subheader }}
                subheader="Monthly Peak Load"
              />
              <CardContent>
                <Typography variant="h5" component="h2">
                  {formatFigure(
                    monthlyTotalizerDataQuery?.data?.data?.monthlyPeak / 1000
                  )}{" "}
                  <span style={{ fontSize: 14 }}>kW</span>
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          {monthlyTotalizerDataQuery.status === "loading" ? (
            skeleton
          ) : (
            <div className={classes.card} style={{ width: "100%" }}>
              <Typography
                variant="caption"
                style={{
                  color: "#bcaaa4",
                  fontSize: 16,
                }}
              >
                Peak Load
              </Typography>
              <LoadChart2
                load={percentPeakLoad2(
                  monthlyTotalizerDataQuery?.data?.data?.monthlyPeak / 1000
                )}
                label={"Peak Load"}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          {monthlyTotalizerDataQuery.status === "loading" ? (
            skeleton
          ) : (
            <div className={classes.card}>
              <Typography
                variant="caption"
                style={{
                  color: "#bcaaa4",
                  fontSize: 16,
                }}
              >
                Load Factor
              </Typography>
              <LoadChart2
                load={calculateLoadFactor2(
                  monthlyTotalizerDataQuery?.data?.data?.monthlyPeak / 1000,
                  periodValue,
                  monthlyTotalizerDataQuery?.data?.data?.totalkWh
                )}
                label={"Load Factor"}
              />
            </div>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          {monthlyTotalizerDataQuery.status === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.charts2}>
              <CardContent>
                <LoadProfileChart
                  period={getMonthYear(periodValue)}
                  data={monthlyTotalizerDataQuery?.data?.data}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
        {/* <Grid xs={12} lg={6} item >
          {
            generatorDataStatus === "loading" ? skeleton : (
              <DailyEnergyInfo classes={classes} generatorData={generatorData} />
            )
          }
        </Grid> */}
      </Grid>
    </div>
  );
};

export default EnergyUsage;
