import { Grid, makeStyles, Snackbar, TextField, Button as MuiButton } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import Input from '../Controls/Input';
import CheckBox from '../Controls/CheckBox';
import Select from '../Controls/Select'
import { roles } from '../../Services/userServices'
import Button from '../Controls/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import { FieldArray, Form, Formik, getIn } from 'formik';
import * as yup from 'yup';
import { UsersContext } from '../../Contexts/usersContext';

import { Alert } from '@material-ui/lab';
import { COLORS } from '../../Constants';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '90%',
            margin: theme.spacing(1),
            backgroundColor: '#E8F0FE',
            borderRadius: '5px'
        },

        '& .MuiFormControlLabel-root': {
            //width: '90%',
            margin: theme.spacing(1),
            backgroundColor: 'transpanrent',
        }
    },
    meterWrapper: {
        display: 'flex'
    },
    meterButton: {
        color: COLORS.MENU_ITEM_INACTIVE_COLOR,
        borderColor: COLORS.MENU_ITEM_INACTIVE_COLOR,
        margin: theme.spacing(1)
    },
    removeButton: {
        margin: theme.spacing(1.5),
        color: "#F50057",
        borderColor: "#F50057",
        padding: "5px"
    }
}));
const initialValues = {
    id: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    role: "",
    isMember: false,
    meters: [{ meterSerial: "" }]
}

const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
    fullName: yup.string('Enter your full name')
        .required('Full Name is required')
        .min(2, 'Insufficiant characters'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phoneNumber: yup.string('Enter your phone number')
        .matches(phoneRegExp, "Enter a valid phone number")
        .required('Phone Number is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    confirmPassword: yup
        .string('Enter your confirm password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    isMember: yup.boolean(),
    meters: yup.array()
        .of(
            yup.object().shape({
                meterSerial: yup.string("Enter Meter Serial").required('Meter serial is required.')
            }))
        .when('isMember', {
            is: true,
            then: yup.array().required("Please enter meter number for this user.")
        })

});

const UserForm = () => {

    const { mutateAsync } = useContext(UsersContext);
    const [hasError, setHasError] = useState(false);
    const [userCreated, setUserCreated] = useState(false)
    const closeErrorSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setHasError(false);
    }

    const closeSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUserCreated(false);
    }

    const classes = useStyles();
    
    return (
        <>
            <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                    await mutateAsync(values, {
                        onError: (error) => {
                            setHasError(true);
                        },
                        onSuccess: () => {
                            resetForm()
                            setUserCreated(true);
                        }
                    });
                }}>
                {
                    ({ values, touched, errors, handleReset, handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
                        <Form className={classes.root} onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={8} sm = {6} lg={6} >
                                    <Input
                                        label="Full Name"
                                        name="fullName"
                                        type='text'
                                        value={values.fullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.fullName && Boolean(errors.fullName)}
                                        helperText={touched.fullName &&
                                            errors.fullName ? errors.fullName : ""}
                                    />

                                    <Input name="email"
                                        label="Email"
                                        type='text'
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={
                                            touched.email && errors.email ? errors.email : ""
                                        }
                                    />
                                    <Input
                                        name="phoneNumber"
                                        label="Phone Number"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""}
                                    />
                                    <CheckBox name="isMember" label="Is TEC Memebr?"
                                        value={values.isMember}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        // error={touched.isMember && Boolean(errors.isMember)}
                                        // helperText={touched.isMember && errors.isMember ? errors.isMember : ""}
                                    />

                                </Grid>

                                <Grid item xs={8} sm = {6} lg={6}>
                                    <Input
                                        name="password"
                                        label="Password"
                                        value={values.password}
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password ? errors.password : ""}
                                    />
                                    <Input
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        value={values.confirmPassword}
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                        helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ""}
                                    />

                                    <Select name="role"
                                        label="User Role"
                                        value={values.role}
                                        onChange={handleChange}
                                        options={roles} />
                                    {values.isMember ? (
                                        <FieldArray
                                            name='meters'
                                            validateOnChange={false}
                                            render={({ push, remove }) => {

                                                const onMeterSerialChange = (e, index) => {
                                                    const { value } = e.target;
                                                    let { meters } = values;
                                                    meters[index].meterSerial = value;
                                                    setFieldValue('meters', meters)
                                                }
                                                return (<div>
                                                    {
                                                        values.meters && values.meters.length > 0 ?
                                                            (
                                                                values.meters.map((meter, index) => {
                                                                    const meterSerial = `values.meters[${index}].meterSerial`;
                                                                    const meterSerialTouched = getIn(touched, meterSerial)
                                                                    const meterSerialTouchedError = getIn(errors, meterSerial);
                                                                    return (<div key={index} >
                                                                        <div className={classes.meterWrapper}>
                                                                            <TextField
                                                                                label="Meter Serial"
                                                                                name={meterSerial}
                                                                                value={meter.meterSerial}
                                                                                onChange={(e) => onMeterSerialChange(e, index)}
                                                                                onBlur={handleBlur}
                                                                                error={meterSerialTouched && Boolean(meterSerialTouchedError)}
                                                                                helperText={
                                                                                    meterSerialTouched && meterSerialTouchedError
                                                                                        ? meterSerialTouchedError
                                                                                        : ""
                                                                                }
                                                                            />
                                                                            <MuiButton
                                                                                className={classes.removeButton}
                                                                                margin="normal"
                                                                                type="button"
                                                                                color="secondary"
                                                                                variant="outlined"
                                                                                onClick={() => remove(index)}>
                                                                                <RemoveIcon />
                                                                            </MuiButton>
                                                                        </div>
                                                                        <MuiButton
                                                                            className={classes.meterButton}
                                                                            margin="normal"
                                                                            type="button"
                                                                            color="primary"
                                                                            variant="outlined"
                                                                            onClick={() => push({ meterSerial: "" })}>
                                                                            Add
                                                                        </MuiButton>
                                                                    </div>
                                                                    )
                                                                })
                                                            ) :
                                                            (
                                                                <MuiButton className={classes.meterButton}
                                                                    margin="normal"
                                                                    type="button"
                                                                    color="primary"
                                                                    variant="outlined" onClick={() => push({ meterSerial: "" })}>
                                                                    Add Meter
                                                                </MuiButton>
                                                            )
                                                    }
                                                </div>
                                                )
                                            }} />

                                    ) : null}
                                    <Button disabled={isSubmitting || JSON.stringify(values) === JSON.stringify(initialValues)} color="primary" size="large" text="Submit" type="submit" />
                                    <Button disabled={isSubmitting} onClick={handleReset} color="default" size="large" text="Reset" />
                                </Grid>
                            </Grid>

                        </Form>
                    )
                }


            </Formik>
            <div>
                <Snackbar open={hasError}
                    autoHideDuration={6000}
                    onClose={closeErrorSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert severity="error" onClose={closeErrorSnackbar}>Something went wrong!</Alert>
                </Snackbar>
                <Snackbar open={userCreated}
                    autoHideDuration={3000}
                    onClose={closeSuccessSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert severity="success" onClose={closeSuccessSnackbar}>User successfully created!</Alert>
                </Snackbar>
            </div>
        </>
    )
}

export default UserForm
