
import React, { createContext } from "react";
import { useQuery } from "react-query";
import API from "../api/API";

export const CustomersMapDataContext = createContext();

const fetchCustomersData = async (key) =>{
    const result = await API.get('/total-transactions-by-meter');
    const salesTotalResult = await API.get('/total-sales');
    const {totalSale} = salesTotalResult.data;
    const customers = result.data.filter(cs=>cs.meterInfo.coords).map(d=>{
        const {totalPurchase} = d;
        const percentOfTotalSales= (totalPurchase/totalSale)*100;
        return {percentOfTotalSales, ...d};
    });
    return customers;
}

export const CustomersMapDataProvider = ({children})=>{

    const customersDataQuery = useQuery('customer-map-data', fetchCustomersData, { refetchOnWindowFocus: false });

    return(
        <CustomersMapDataContext.Provider value={
            {
                customersDataQuery
            }
        }>
            {children}
        </CustomersMapDataContext.Provider>
    )
}