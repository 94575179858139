
import { IconButton } from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import ReactMapGl, { Marker, Popup } from 'react-map-gl';
import { CustomersMapDataContext } from "../../Contexts/customersMapsDataContext.";
const CustomersMap = () => {
  const [viewport, setViewPort] = useState({
    latitude: 6.81112,
    longitude: -9.94031,
    width: '100vw',
    height: '100vh',
    zoom: 15.5
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const getCoordinates = (meterInfo) => {
    if (meterInfo.coords === undefined)
      return { latitude: 6.81112, longitude: -9.94031 };
    if (meterInfo.coords.length === 0)
      return { latitude: 6.81112, longitude: -9.94031 };
    const coodinates = meterInfo.coords.split(',');
    const latitude = parseFloat(coodinates[0]);
    const longitude = parseFloat(coodinates[1]);
    return { latitude, longitude }
  }
  const iconButtonStyle = (percentOfSales) => {
    let color = "#a1f480";
    if (percentOfSales > 0.7)
      color = "#3dbdc2"
    if (percentOfSales > 0.3 && percentOfSales <= 0.7)
      color = "#0c9cee";
    return { color };
  }

  const setFontSize = (percentOfSales) => {
    let size = "small";
    if (percentOfSales > 0.7)
      size = "default";
    if (percentOfSales > 0.3 && percentOfSales <= 0.7)
      size = "large";
    return size
  }
  const { customersDataQuery } = useContext(CustomersMapDataContext);
  return (
    <div>
      <ReactMapGl {...viewport} mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        // mapStyle="mapbox://styles/maximus-aaron/ckhfgon610ch119pivv0uhwrx"
        mapStyle="mapbox://styles/maximus-aaron/ckhzjxlul1lbl19lifbybbtpg"
        onViewportChange={viewport => { setViewPort(viewport) }}
      >
        {customersDataQuery.status === 'success' && (
          customersDataQuery.data.map(customer => (
            <Marker key={customer.meterInfo.meterSerial} latitude={getCoordinates(customer.meterInfo).latitude} longitude={getCoordinates(customer.meterInfo).longitude}
            >
              <IconButton style={iconButtonStyle(customer.percentOfTotalSales)} onClick={e => {
                e.preventDefault();
                setSelectedCustomer(customer);
              }} 
              // onMouseOver={e => {
              //   setSelectedCustomer(customer);
              // }}
              //   onMouseOut={e => {
              //     setSelectedCustomer(null);
              //   }}
              >
                <Brightness1 fontSize={setFontSize(customer.percentOfTotalSales)} />
              </IconButton>
            </Marker>)))}
        {selectedCustomer ? (
          <Popup latitude={getCoordinates(selectedCustomer.meterInfo).latitude}
            longitude={getCoordinates(selectedCustomer.meterInfo).longitude}
            onClose={() => setSelectedCustomer(null)}
          >
            <div>
              <h2>{selectedCustomer.meterInfo.customerName}</h2>
              <p>{selectedCustomer.meterInfo.meterSerial}</p>
              {(selectedCustomer.meterInfo.male + selectedCustomer.meterInfo.female)>0 &&
                <p>{`Males: ${selectedCustomer.meterInfo.male} and Females: ${selectedCustomer.meterInfo.female}`}</p>
              }
              <p>{`Total Purchase: ${selectedCustomer.totalPurchase.toLocaleString()} LRD`}</p>
              <p>{`Recharge Count: ${selectedCustomer.rechargeCount} times`}</p>
              <p>{`Last Recharge:  ${new Date(selectedCustomer.lastRechargeDate._seconds * 1000).toGMTString()}`}</p>
            </div>
          </Popup>
        ) : null}
      </ReactMapGl>
    </div>
  );
};

export default CustomersMap;
