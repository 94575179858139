import { Card, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react';
import { COLORS } from '../../Constants';

const useStyles = makeStyles(theme =>(
{
    root:{
        backgroundColor: "transparent",
        color:'rgb(255, 250, 255)',
        width:'100%'
    },
    pageHeader:{
        padding:theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            display:'flex',
        },
        [theme.breakpoints.down("sm")]: {
            display:'block',
        },
        marginBottom:theme.spacing(2)
    },
    pageIcon: {
        display:'inline-block',        
        padding:theme.spacing(2),
        color:COLORS.MENU_ITEM_INACTIVE_COLOR,
        backgroundColor:COLORS.NAV_BACKGROUND
    },
    pageTitle:{
        [theme.breakpoints.up("sm")]: {
            paddingLeft:theme.spacing(4),
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft:theme.spacing(1),
            '& .MuiTypography-h5':{
                fontSize:theme.spacing(2.5)
            },
            '& .MuiTypography-subtitle2':{
                fontSize:theme.spacing(1.5)
            }
        },
        '& .MuiTypography-subtitle2':{
            opacity:'0.7',
            fontSize:theme.spacing(1.5)
        }
    }
}
));

const PageHeader = (props) => {
    const { icon, title, subTitle } = props;
    const classes = useStyles();
    return (
        <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>
                    {icon}
                </Card>
                <div className={classes.pageTitle}>
                    <Typography
                        variant="h5"
                        component="div" >
                        {title}</Typography>
                    <Typography
                        variant="subtitle2"
                        component="div" >
                        {subTitle}</Typography>
                </div>
            </div>
        </Paper>
    )
}

export default PageHeader
