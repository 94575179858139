import {
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Button from "../Controls/Button";
import Input from "../Controls/Input";
import { useAuth } from "../../Contexts/authContext";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { COLORS } from "../../Constants";
import PageHeader from "../PageHeader/PageHeader";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: COLORS.ROOT_BACKGROUND,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
    },
  },
  loginContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
  },
  alert: {
    maxWidth: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  formRoot: {
    "& .MuiFormControl-root": {
      width: "90%",
      margin: theme.spacing(1),
      backgroundColor: "#E8F0FE",
      borderRadius: "5px",
    },
    "& .MuiGrid-item": {
      minWidth: "100%",
      margin: theme.spacing(1),
    },
    "& .MuiGrid-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5),
      },
    },
    background: COLORS.NAV_BACKGROUND,
    [theme.breakpoints.up("sm")]: {
      minWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
    // text:{
    //     width: '100%',
    // }
  },
}));
const initialValues = {
  email: "",
  password: "",
};
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
const Login = () => {
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { login } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await login(values.email, values.password);
        history.push("/");
      } catch (error) {
        setError("Incorrect username or password");
        setHasError(true);
      }
    },
  });
  return (
    <div className={classes.root}>
      <PageHeader
        title="Login Page"
        subTitle="Login Page for TEC Dashboard App"
        icon={<LockOpenIcon fontSize="large" />}
      />
      <div className={classes.loginContent}>
        <form className={classes.formRoot} onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Input
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                name="password"
                placeholder="Password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <Input
                                name="password"
                                label="Password"
                                value={formik.values.password}
                                type="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            /> */}
              <Button
                disabled={formik.isSubmitting}
                color="primary"
                size="large"
                text="Login"
                type="submit"
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={classes.alert}>
        <Collapse in={hasError}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setHasError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Collapse>
      </div>
    </div>
  );
};

export default Login;
