import { Grid, makeStyles, Snackbar, TextField, Button as MuiButton } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import Input from '../Controls/Input';
import Select from '../Controls/Select'
import { roles } from '../../Services/userServices'
import Button from '../Controls/Button';
import { FieldArray, getIn, Formik, Form } from 'formik';
import * as yup from 'yup';
import { UsersContext } from '../../Contexts/usersContext';
import RemoveIcon from '@material-ui/icons/Remove';
import { Alert } from '@material-ui/lab';
import CheckBox from '../Controls/CheckBox';
import { COLORS } from '../../Constants';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '90%',
            margin: theme.spacing(1),
            backgroundColor: '#E8F0FE',
            borderRadius: '5px'
        },

        '& .MuiFormControlLabel-root': {
            //width: '90%',
            margin: theme.spacing(1),
            backgroundColor: 'transpanrent',
        }
    },
    meterWrapper: {
        display: 'flex'
    },
    meterButton: {
        color: COLORS.MENU_ITEM_INACTIVE_COLOR,
        borderColor: COLORS.MENU_ITEM_INACTIVE_COLOR,
        margin: theme.spacing(1)
    },
    removeButton: {
        margin: theme.spacing(1.5),
        color: "#F50057",
        borderColor: "#F50057",
        padding: "5px"
    }
}));

const phoneRegExp = /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
    fullName: yup.string('Enter your full name')
        .required('Full Name is required')
        .min(2, 'Insufficiant characters'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phoneNumber: yup.string('Enter your phone number')
        .matches(phoneRegExp, "Enter a valid phone number")
        .required('Phone Number is required'),
    isMember: yup.boolean(),
    meters: yup.array()
        .of(
            yup.object().shape({
                meterSerial: yup.string("Enter Meter Serial").required('Meter serial is required.')
            }))
        .when('isMember', {
            is: true,
            then: yup.array().required("Please enter meter number for this user.")
        })
});

const EditUserForm = ({ userToEdit }) => {

    const [initialValues, setInitialValues] = useState({
        uid: "",
        fullName: "",
        email: "",
        phoneNumber: "",
        role: "",
        isMember: false,
        meters: [{ meterSerial: "" }]
    });


    const { updateUser, setUserEdited } = useContext(UsersContext);
    const [hasEditError, setEditHasError] = useState(false);

    useEffect(() => {
        if (userToEdit !== null) {
            const userRole = roles.find(r => r.role === userToEdit.role);
            if (userRole === undefined) return;
            const { id } = userRole;
            setInitialValues({ ...userToEdit, role: id });
        }
    }, [userToEdit])

    const closeErrorSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setEditHasError(false);
    }

    const classes = useStyles();

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}
                onSubmit={async (values) => {
                    setEditHasError(false);
                    setUserEdited(false);
                    await updateUser(values, {
                        onError: (error) => {
                            setEditHasError(true);

                        }
                    });
                }}>
                {
                    ({ values, touched, errors, handleReset, handleSubmit, handleChange, handleBlur,setFieldValue ,isSubmitting }) => (
                        <Form className={classes.root} onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={8} sm = {6} lg={6}>
                                    <Input
                                        label="Full Name"
                                        name="fullName"
                                        type='text'
                                        value={values.fullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.fullName && Boolean(errors.fullName)}
                                        helperText={touched.fullName &&
                                            errors.fullName ? errors.fullName : ""}
                                    />

                                    <Input name="email"
                                        label="Email"
                                        type='text'
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={
                                            touched.email && errors.email ? errors.email : ""
                                        }
                                    />

                                    <CheckBox name="isMember" label="Is TEC Memebr?"
                                        value={values.isMember}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        // error={touched.isMember && Boolean(errors.isMember)}
                                        // helperText={touched.isMember && errors.isMember ? errors.isMember : ""}
                                    />

                                </Grid>
                                <Grid item xs={8} sm = {6} lg={6}>
                                    <Input
                                        name="phoneNumber"
                                        label="Phone Number"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""}
                                    />
                                    <Select name="role"
                                        label="User Role"
                                        value={values.role}
                                        onChange={handleChange}
                                        options={roles} />
                                    {values.isMember ? (
                                        <FieldArray
                                            name='meters'
                                            validateOnChange={false}
                                            render={({ push, remove }) => {
                                                
                                                const onMeterSerialChange = (e, index)=>{
                                                    const {value} = e.target;
                                                    let {meters} = values;
                                                    meters[index].meterSerial = value;
                                                    setFieldValue('meters', meters)
                                                }
                                                return (<div>
                                                    {
                                                        values.meters && values.meters.length > 0 ?
                                                            (
                                                                values.meters.map((meter, index) => {
                                                                    const meterSerial = `values.meters[${index}].meterSerial`;
                                                                    const meterSerialTouched = getIn(touched, meterSerial)
                                                                    const meterSerialTouchedError = getIn(errors, meterSerial);
                                                                    return (<div key={index} >
                                                                        <div className={classes.meterWrapper}>
                                                                            <TextField
                                                                                label="Meter Serial"
                                                                                name={meterSerial}
                                                                                value={meter.meterSerial}
                                                                                onChange={(e)=>onMeterSerialChange(e, index)}
                                                                                onBlur={handleBlur}
                                                                                error={meterSerialTouched && Boolean(meterSerialTouchedError)}
                                                                                helperText={
                                                                                    meterSerialTouched && meterSerialTouchedError
                                                                                        ? meterSerialTouchedError
                                                                                        : ""
                                                                                }
                                                                            />
                                                                            <MuiButton
                                                                                className={classes.removeButton}
                                                                                margin="normal"
                                                                                type="button"
                                                                                color="secondary"
                                                                                variant="outlined"
                                                                                onClick={() => remove(index)}>
                                                                                <RemoveIcon />
                                                                            </MuiButton>
                                                                        </div>
                                                                        <MuiButton
                                                                            className={classes.meterButton}
                                                                            margin="normal"
                                                                            type="button"
                                                                            color="primary"
                                                                            variant="outlined"
                                                                            onClick={() => push({ meterSerial: "" })}>
                                                                            Add
                                                                        </MuiButton>
                                                                    </div>
                                                                    )
                                                                })
                                                            ) :
                                                            (
                                                                <MuiButton className={classes.meterButton}
                                                                margin="normal"
                                                                type="button"
                                                                color="primary"
                                                                variant="outlined" onClick={() => push({ meterSerial: "" })}>
                                                                    Add Meter
                                                                </MuiButton>
                                                            )
                                                    }
                                                </div>
                                                )
                                            }} />

                                    ) : null}
                                    <Button disabled={isSubmitting || JSON.stringify(values) === JSON.stringify(initialValues)} color="primary" size="large" text="Submit" type="submit" />
                                    <Button disabled={isSubmitting} onClick={handleReset} color="default" size="large" text="Reset" />
                                </Grid>
                            </Grid>

                        </Form>
                    )
                }


            </Formik>
            <div>
                <Snackbar open={hasEditError}
                    autoHideDuration={6000}
                    onClose={closeErrorSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert severity="error" onClose={closeErrorSnackbar}>Something went wrong!</Alert>
                </Snackbar>
            </div>
        </>
    )
}

export default EditUserForm
