import { TextField } from '@material-ui/core';
import React from 'react'

const Input = (props) => {
    const { name, label, value, onChange, type='text', ...others } = props;
    return (
        <TextField
            name={name}
            placeholder={label}
            value={value}
            onChange={onChange}
            type={type}
            variant="outlined"
            {...others}
            />
    )
}

export default Input
