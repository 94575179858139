import React, { useState } from 'react';
import Arrow from './Arrow';
import Carousel from './Carousel';
import { makeStyles } from '@material-ui/core';
import { orderBy } from 'lodash';

const useStyles = makeStyles ((theme)=>({
    carousel:{
        textAlign: 'center',   
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
}));

const CarouselSlide = ({peakLoads}) => {
    const sortedPeakLoads = orderBy(peakLoads.filter(pl=>pl.peakLoad!==""), ['peakLoad', 'month', 'year'], ['desc', 'asc', 'asc']);
    const [index, setIndex] = useState(0);
    const peakLoad = sortedPeakLoads[index];
    const numSlides = sortedPeakLoads.length;
    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (index + increment + numSlides) % numSlides;
        setIndex(newIndex);
    };
    const classes = useStyles();
    return (
        <div className={classes.carousel}>
            <Arrow
                direction='left'
                clickFunction={() => onArrowClick('left')}
            />
            <Carousel peakLoad={peakLoad} />
            <Arrow
                direction='right'
                clickFunction={() => onArrowClick('right')}
            />
        </div>
    )
}

export default CarouselSlide
