import React, { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { customerAPI } from "../api/API";


const customerBalanceContext = createContext();

export const useCustomerBalanceContext = () => {
    return useContext(customerBalanceContext);
}

const fetchCustomersBalance = async () => {
    const result = await customerAPI.get("/customers");
    return result.data;
}


export const CustomerBalanceProvider = ({ children }) => {
    const customerBalanceQuery = useQuery("customers-balance" ,fetchCustomersBalance, { refetchOnWindowFocus: false });
    
    const value = {
        customerBalanceQuery
    };

    return (
        <customerBalanceContext.Provider value={value}>
            {children}
        </customerBalanceContext.Provider>
    );
}



