import React, { createContext, useState } from "react";
import API from "../api/API";
import { useQuery } from "react-query";
import { orderBy, sumBy } from "lodash";
import axios from "axios";

export const EnergyDataContext = createContext();

const fetchGeneratedEnergies = async ({ queryKey }) => {
  const [, year] = queryKey;
  const result = await API.get(`/monthly-outage-by-year/${year}`);
  const energyGenerated = orderBy(result.data, ["month"], ["asc"]);
  const energyUsage = {
    totalUsedkWh: sumBy(energyGenerated, (gd) => gd.kWhUsed),
    totalHoursOfOperation: sumBy(
      energyGenerated,
      (gd) => gd.durationOfOperation
    ),
    totalScheduledHours: sumBy(energyGenerated, (gd) => gd.totalScheduledHours),
    totalPlannedOutage: sumBy(energyGenerated, (gd) => gd.plannedOutage),
    totalUnplannedOutage: sumBy(energyGenerated, (gd) => gd.unplannedOutage),
  };
  return { energyGenerated, energyUsage };
};

const fetchSalesData = async ({ queryKey }) => {
  const [, year] = queryKey;
  const result = await API.get(`/monthly-transactions-per-year/${year}`);
  return result.data;
};
const fetchYearlyEnergyGeneration = async ({ queryKey }) => {
  const [, year] = queryKey;
  const result = await API.get(`/monthly-energy-generation/all/${year}`);
  let data = result.data;
  if (data.length === 0) {
    //read data from another api
    const response = await axios.get(
      `${process.env.REACT_APP_TOTALIZERS_API_URL}/api/v1/totalizers/year/${year}`
    );
    data = response.data.data;
  }
  const energyGeneration = orderBy(data, ["year", "month"], ["asc"]);
  return energyGeneration;
};

//Fetch current data from totalizers api
// const fetchCurrentData = async (key) => {
//   const response = await axios.get(`${process.env.REACT_APP_TOTALIZERS_API_URL}/api/v1/totalizers/current/2022-9-27`);
//   return response.data.data;
// }

const fetchAllEnergyGeneration = async ({ queryKey }) => {
  const result = await API.get(`/monthly-energy-generation/all`);
  const data = result.data;
  return data;
};
const fetchTotalConsumptions = async (key) => {
  const result = await API.get(`/total-energy-consumed`);
  return orderBy(result.data, "customerType", "asc");
};
const fetchConsumptionData = async (key) => {
  const result = await API.get(`/energy-consumption-per-customer-type`);
  const consumptions = orderBy(result.data, ["year", "month"], ["asc"]);
  return consumptions;
};
const fetchCurrentRate = async (key) => {
  //const response = await axios.get('https://us-central1-cbl-exchange-rates.cloudfunctions.net/cbl_api/');
  return {
    buyingRate: 188.15,
  };
};
const fetchBeneficiary = async (key) => {
  const result = await API.get("/number-of-beneficiaries");
  return result.data;
};

const getArrayOfYears = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }
  return years.map((y, i) => {
    return {
      id: i + 1,
      year: y.toString(),
    };
  });
};

const years = getArrayOfYears(2019);
export const EnergyDataProvider = ({ children }) => {
  const getCurrentYearIndex = () => {
    const currentMonth = new Date().getMonth();
    const yearIndex = currentMonth >= 2 ? 0 : 1;
    return yearIndex;
  };

  const beneficiaryResult = useQuery("beneficiary", fetchBeneficiary, {
    refetchOnWindowFocus: false,
  });
  const [selectedYear, setSelectedYear] = useState(
    years[getCurrentYearIndex()]
  );
  const generatedEnergiesResult = useQuery(
    ["generated-energies", selectedYear.year],
    fetchGeneratedEnergies,
    { refetchOnWindowFocus: false }
  );
  const salesDataResult = useQuery(
    ["sales-data", selectedYear.year],
    fetchSalesData,
    { refetchOnWindowFocus: false }
  );
  const currentExchangeRateResult = useQuery("exchange-rate", fetchCurrentRate);
  const energyConsumptionResult = useQuery(
    "consumption",
    fetchConsumptionData,
    { refetchOnWindowFocus: false }
  );
  const totalConsumptionResult = useQuery(
    "total-consumption",
    fetchTotalConsumptions,
    { refetchOnWindowFocus: false }
  );
  const yearlyEnergyGenerationQuery = useQuery(
    ["monthly-energy-generation", selectedYear.year],
    fetchYearlyEnergyGeneration,
    { refetchOnWindowFocus: false }
  );
  const energyGenerationQuery = useQuery(
    "monthly-energy-generations",
    fetchAllEnergyGeneration,
    { refetchOnWindowFocus: false }
  );
  // const currentDataQuery = useQuery('current-data', fetchCurrentData, { refetchOnWindowFocus: false });

  return (
    <EnergyDataContext.Provider
      value={{
        beneficiary: beneficiaryResult.data,
        beneficiaryStatus: beneficiaryResult.status,
        generatedEnergiesData: generatedEnergiesResult.data,
        generatedEnergiesStatus: generatedEnergiesResult.status,
        salesData: salesDataResult.data,
        salesDataStatus: salesDataResult.status,
        currentExchangeRateResult,
        energyConsumptionResult,
        totalConsumptionResult,
        setSelectedYear,
        selectedYear,
        years,
        yearlyEnergyGenerationQuery,
        energyGenerationQuery,
        // currentDataQuery
      }}
    >
      {children}
    </EnergyDataContext.Provider>
  );
};

export default EnergyDataProvider;
