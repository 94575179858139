import React, { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { powerAPI } from "../api/API";

const latestReadingContext = createContext();

export const useLatestReadingContext = () => {
  return useContext(latestReadingContext);
};

const fetchTotalizerReadingsForChart = async ({ queryKey }) => {
  const [, date] = queryKey;
  const { day, month, year } = date;
  const result = await powerAPI.get(
    `/generation-data/totalizer-data-for-chart?date=${year}-${month + 1}-${day}`
  );
  const data = result.data;
  return data;
};

const fetchLastAgetoReadings = async ({ queryKey }) => {
  const result = await powerAPI.get(`/generation-data/last-reading`);
  const data = result.data;
  return data;
};

const fetchDailyGenerationEnergyData = async ({ queryKey }) => {
  const [, date] = queryKey;
  const result = await powerAPI.get(
    `/generation-data/daily-energy-total?date=${date}`
  );
  const data = result.data;
  return data;
};

const fetchLastTotalizerReading = async ({ queryKey }) => {
  const result = await powerAPI.get(`/generation-data/last-totalizer-reading`);
  const data = result.data;
  return data;
};

const LatestReadingDataProvider = ({ children }) => {
  const today = new Date();
  const [date, setDate] = useState({
    month: today.getMonth(),
    day: today.getDate(),
    year: today.getFullYear(),
  });

  const newDate = new Date();
  const yesterday = new Date(newDate.setDate(newDate.getDate() - 1));

  const [genDataDate, setGenDataDate] = useState(
    yesterday.toISOString().split("T")[0]
  );

  const [totolizerChartOption, setTotolizerChartOption] = useState("demand");

  const totalizerReadingsForChartQuery = useQuery(
    ["totalizer-readings-for-chart", date],
    fetchTotalizerReadingsForChart,
    { refetchOnWindowFocus: false }
  );
  const lastTotalizerReadingQuery = useQuery(
    "last-totalizer-reading",
    fetchLastTotalizerReading,
    { refetchOnWindowFocus: true }
  );

  const dailyGenerationDataQuery = useQuery(
    ["daily-generation-data", genDataDate],
    fetchDailyGenerationEnergyData,
    { refetchOnWindowFocus: false }
  );

  const lastAgetoReadingQuery = useQuery(
    "last-ageto-reading",
    fetchLastAgetoReadings,
    { refetchOnWindowFocus: true }
  );

  const value = {
    date,
    setDate,
    totalizerReadingsForChartQuery,
    totolizerChartOption,
    setTotolizerChartOption,
    lastTotalizerReadingQuery,
    genDataDate,
    setGenDataDate,
    dailyGenerationDataQuery,
    lastAgetoReadingQuery,
  };

  return (
    <latestReadingContext.Provider value={value}>
      {children}
    </latestReadingContext.Provider>
  );
};

export default LatestReadingDataProvider;
