import API from "../api/API";
import React, { createContext, useContext, useState } from 'react';
import { useMutation, useQuery } from "react-query";

const customerDataContext = createContext();

const fetchCustomerData = async ({ queryKey }) => {
    const [, meterSerial] = queryKey;
    if(!meterSerial || meterSerial==="") return;
    const result = await API.get(`/customers/${meterSerial}`);
    return result.data;
}
const fetchCustomerEnergyConsumption = async ({queryKey}) =>{
    const [, meterSerial] = queryKey;
    if(!meterSerial || meterSerial==="") return;
    const result = await API.get(`/energy-consumption-per-meter/${meterSerial}`);
    return result.data;
}

const fetchCustomerTransactionRecord = async ({queryKey})=>{
    const [, meterSerial] = queryKey;
    if(!meterSerial || meterSerial==="") return;
    const result = await API.get(`/total-transaction-by-meter/${meterSerial}`);
    return result.data;
}
const resetMeter = async (meterSerial)=>{
    if(!meterSerial || meterSerial==="") return;
    const result = await API.post(`/customers/reset-meter/${meterSerial}`);
    return result.data;
}
export const useCustomerData = () => {
    return useContext(customerDataContext);
}

export const CustomerDataProvider = ({ children }) => {
    const [meterSerial, setMeterSerial] = useState()
    const customerQuery = useQuery(['customer-info', meterSerial], fetchCustomerData);
    const transactionRecordQuery = useQuery(['trans-record', meterSerial], fetchCustomerTransactionRecord);
    const energyConsumptionQuery = useQuery(['energy-consumption', meterSerial], 
                                                fetchCustomerEnergyConsumption);
    const {mutateAsync: resetMyMeter} = useMutation( resetMeter);

    const value = {
        customerQuery,
        energyConsumptionQuery,
        setMeterSerial,
        meterSerial,
        transactionRecordQuery,
        resetMyMeter
    }
    return (
        <customerDataContext.Provider value={value}>
            {children}
        </customerDataContext.Provider>
    )
}
