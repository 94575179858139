import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core'
import React from 'react'

const Select = (props) => {
    const { name, label, value, onChange, options } = props
    return (
        <FormControl variant="outlined">
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                placeholder={label}
                name={name}
                value={value}
                onChange={onChange}
            >
                <MenuItem value = "">None</MenuItem>
                {
                    options.map(item=>(<MenuItem key={item.id} value={item.id}>{item.role}</MenuItem>))
                }
            </MuiSelect>
        </FormControl>
    )
}

export default Select
