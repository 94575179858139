import React from "react";

import ReactApexChart from "react-apexcharts";

function LoadProfileChart({ data, period }) {
  const peakLoad = data?.peakLoad || [];
  const minLoad = data?.minLoad || [];
  const avgLoad = data?.avgLoad || [];

  const hours = data?.hours || [];

  const series = [
    {
      name: "Peak Load",
      data: peakLoad,
    },
    {
      name: "Min Load",
      data: minLoad,
    },
    {
      name: "Avg Load",
      data: avgLoad,
    },
  ];

  const options = {
    chart: {
      type: "area",
      foreColor: "#ccc",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#05D7A0", "#F5DC50", "#82D2F5"],
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },

    markers: {
      size: 0.5,
      colors: ["#000524"],
      strokeColor: "#00BAEC",
      strokeWidth: 0.5,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: `${period} Load Profile`,
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    subtitle: {
      text: "Load Profile",
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    tooltip: {
      theme: "light",
    },
    labels: hours,
    yaxis: {
      show: true,
      //format fixed to 2 decimal places
      labels: {
        formatter: function (figure) {
          return `${(figure / 1000).toFixed(1)} kW`;
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tickAmount: 18,
    },
  };

  return (
    <div>
      <ReactApexChart
        height={450}
        options={options}
        series={series}
        type="area"
      />
    </div>
  );
}

export default LoadProfileChart;
