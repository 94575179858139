import React, { createContext, useState } from "react";
import { useQuery } from "react-query";
import { powerAPI } from "../api/API";
export const GeneratorDataContext = createContext();

const fetchGetUniqueMonths = async ({ queryKey }) => {
  const result = await powerAPI.get("/generation-data/unique-months");
  return result.data;
};

const fetchMonthlyTotalizerData = async ({ queryKey }) => {
  const [, period] = queryKey;
  const month = `${period.year}-${period.month}`;
  const result = await powerAPI.get(
    `/generation-data/monthly-totalizer-data?period=${month}`
  );
  return result.data;
};

export const GeneratorDataProvider = ({ children }) => {
  const today = new Date();
  const [period, setPeriod] = useState({
    year: today.getUTCFullYear(),
    month: today.getUTCMonth() + 1,
  });

  const uniqueMonthsQuery = useQuery("unique-months", fetchGetUniqueMonths, {
    refetchOnWindowFocus: false,
  });
  const monthlyTotalizerDataQuery = useQuery(
    ["monthly-totalizer-energy-usage", period],
    fetchMonthlyTotalizerData,
    { refetchOnWindowFocus: false }
  );

  return (
    <GeneratorDataContext.Provider
      value={{
        setPeriod,
        period,
        uniqueMonthsQuery,
        monthlyTotalizerDataQuery,
      }}
    >
      {children}
    </GeneratorDataContext.Provider>
  );
};
