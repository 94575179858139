import React from "react";
import AgetoRecordCardItem from "./AgetoRecordCardItem";
import { Grid } from "@material-ui/core";

const AgetoControllerRecord = ({ dailyEnergyRecord }) => {
  if (!dailyEnergyRecord) {
    return (
      <div style={{ marginTop: "8px", fontSize: 18, color: "orange" }}>
        Could not load data
      </div>
    );
  }

  const {
    totalLoad,
    batteryDIS,
    batteryCHG,
    pvEnergy,
    genEnergy,
    dailyCO2Offset,
  } = dailyEnergyRecord;

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: 6 }}>
        <Grid item xs={6} sm={6}>
          <AgetoRecordCardItem title="Load Served by PV" figure={totalLoad} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <AgetoRecordCardItem
            title="Energy Generated by PV"
            figure={pvEnergy}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <AgetoRecordCardItem
            title="Energy Generated by CAT GEN"
            figure={genEnergy}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <AgetoRecordCardItem title="Battery Discharge" figure={batteryDIS} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <AgetoRecordCardItem title="Battery Charge" figure={batteryCHG} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <AgetoRecordCardItem
            title="CO2 Offset"
            figure={dailyCO2Offset * 1000}
            unit=" kg"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AgetoControllerRecord;
