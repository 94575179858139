import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import DailyTotalizerSummary from "../Components/Dashboard/DailyTotalizerSummary";

const getSeriesData = (value, readings) => {
  if (!readings) return [];
  switch (value) {
    case "demand":
      return [
        {
          name: "Total Demand",
          data: readings.total_true_power_avg,
        },
      ];
    case "totalCurrent":
      return [
        {
          name: "Total Current",
          data: readings.total_current_avg,
        },
      ];
    case "lineCurrents":
      return [
        {
          name: "L1 Current",
          data: readings.L1_current_avg,
        },
        {
          name: "L2 Current",
          data: readings.L2_current_avg,
        },
        {
          name: "L3 Current",
          data: readings.L3_current_avg,
        },
      ];
    default:
      return [
        {
          name: "Total Demand",
          data: readings.total_true_power_avg,
        },
      ];
  }
};

const useStyles = makeStyles({
  root: {
    padding: "4px",
    marginTop: "12px",
  },
});

const TotalizerReadingCharts = ({
  data,
  totolizerChartOption,
  setTotolizerChartOption,
}) => {
  const date = data?.date;
  const dailykWh = data?.dailykWh;
  const peakLoad = data?.peakLoad;
  const readings = data?.readings || {};

  const [seriesData, setSeriesData] = React.useState(
    getSeriesData(totolizerChartOption, readings)
  );

  const handleChange = (event) => {
    setTotolizerChartOption(event.target.value);
    setSeriesData(getSeriesData(event.target.value, readings));
  };

  const options = {
    chart: {
      type: "area",
      foreColor: "#ccc",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#05D7A0", "#F5DC50", "#82D2F5"],
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },

    markers: {
      size: 0.5,
      colors: ["#000524"],
      strokeColor: "#00BAEC",
      strokeWidth: 0.5,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: `Totalizer Recordings`,
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
    subtitle: {
      text: `${new Date(date).toLocaleString("default", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      })}`,
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    tooltip: {
      theme: "light",
    },
    labels: readings?.times,
    yaxis: {
      show: true,
      //format fixed to 2 decimal places
      labels: {
        formatter: function (figure) {
          return totolizerChartOption === "demand"
            ? `${(figure / 1000).toFixed(1)} kW`
            : `${figure.toFixed(1)} A`;
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tickAmount: 18,
    },
  };

  const classes = useStyles();

  if (!readings || readings.length === 0) return <div>No Data</div>;
  return (
    <Box className={classes.root}>
      <div style={{ marginBottom: "12px" }}>
        <DailyTotalizerSummary
          dailykWh={dailykWh}
          peakLoad={peakLoad}
          date={date}
        />
      </div>

      <ReactApexChart
        height={400}
        options={options}
        series={seriesData}
        type="area"
      />
      <FormControl component="fieldset" style={{ color: "white" }}>
        <FormLabel style={{ color: "white", font: "bold" }} component="legend">
          Readings
        </FormLabel>
        <RadioGroup
          style={{ display: "flex", flexDirection: "row" }}
          aria-label="reading"
          name="reading1"
          value={totolizerChartOption}
          onChange={handleChange}
        >
          <FormControlLabel value="demand" control={<Radio />} label="Demand" />
          <FormControlLabel
            value="totalCurrent"
            control={<Radio />}
            label="Total Current"
          />
          <FormControlLabel
            value="lineCurrents"
            control={<Radio />}
            label="Line Currents"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default TotalizerReadingCharts;
