import React, { useContext } from "react";

import { Skeleton } from "@material-ui/lab";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Chip,
  Hidden,
} from "@material-ui/core";
import useStyles from "./styles";
import { EnergyDataContext } from "../../Contexts/energyDataContext";
import YearlyEnergyChart from "../../Charts/YearlyEnergyChart";
import YearlySalesChart from "../../Charts/YearlySalesChart";
import CarouselSlide from "../CarouselSlide/CarouselSlide";
import ConsumptionPerCustomerTypeChart from "../../Charts/ConsumptionPerCustomerTypeChart";
import { COLORS } from "../../Constants";
import TotalConsumptionChart from "../../Charts/TotalConsumptionChart";
import { useLatestReadingContext } from "../../Contexts/latestReadingContext";
import "react-datepicker/dist/react-datepicker.css";
import TotalizerReadingCharts from "../../Charts/TotalizerReadingCharts";
import LastReadingFromTotalizers from "./LastReadingFromTotalizers";
import CustomDatePicker from "./CustomDatePicker";
import AgetoControllerRecord from "./AgetoControllerRecord";
import LastReadingFromAgeto from "./LastReadingFromAgeto";
const Dashboard = () => {
  const {
    beneficiary,
    beneficiaryStatus,
    salesDataStatus,
    salesData,
    currentExchangeRateResult,
    energyConsumptionResult,
    totalConsumptionResult,
    selectedYear,
    setSelectedYear,
    years,
    yearlyEnergyGenerationQuery,
    energyGenerationQuery,
  } = useContext(EnergyDataContext);
  const {
    totalizerReadingsForChartQuery,
    date,
    setDate,
    genDataDate,
    setGenDataDate,
    dailyGenerationDataQuery,
    totolizerChartOption,
    setTotolizerChartOption,
  } = useLatestReadingContext();

  const handleListItemClick = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
  };
  const handleDateChange = (date) => {
    setDate({
      month: date.getMonth(),
      day: date.getDate(),
      year: date.getFullYear(),
    });
  };

  const handleAgetoDateChange = (date) => {
    const dateStr = new Date(date).toISOString().split("T")[0];
    setGenDataDate(dateStr);
  };

  const classes = useStyles();
  const skeleton = (
    <>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="70%" />
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="rect" width={300} height={140} />
    </>
  );
  const labelStyle = { color: "grey", fontSize: 11.5 };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
        justify="flex-start"
        alignItems="center"
        style={{ background: "#41425D", padding: 10, borderRadius: 10 }}
      >
        <Grid item xs={12} sm={6} lg={4}>
          {beneficiaryStatus === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.card}>
              <CardHeader
                classes={{ subheader: classes.subheader }}
                subheader="Direct Beneficiaries"
              />
              <CardContent>
                <Typography
                  variant="h4"
                  component="h2"
                  weight="bold"
                  style={{ marginLeft: "30%" }}
                >
                  {(
                    beneficiary.beneficiary.female +
                    beneficiary.beneficiary.male
                  ).toLocaleString()}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography>Males</Typography>
                    <Typography size="md">
                      {beneficiary.beneficiary.male.toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Females</Typography>
                    <Typography size="md">
                      {beneficiary.beneficiary.female.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {beneficiaryStatus === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.card}>
              <CardHeader
                classes={{ subheader: classes.subheader }}
                subheader="Active Customers"
              />
              <CardContent>
                <Typography
                  variant="h4"
                  component="h2"
                  weight="bold"
                  style={{ marginLeft: "30%" }}
                >
                  {beneficiary.numberOfCustomers.toLocaleString()}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography style={labelStyle}>Residential</Typography>
                    <Typography size="md">
                      {beneficiary.customerTypes.residential}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={labelStyle}>Businesses</Typography>
                    <Typography size="md">
                      {beneficiary.customerTypes.business}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={labelStyle}>Schools</Typography>
                    <Typography size="md">
                      {beneficiary.customerTypes.school}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={labelStyle}>Churches</Typography>
                    <Typography size="md">
                      {beneficiary.customerTypes.church}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={labelStyle}>Clinics</Typography>
                    <Typography size="md">
                      {beneficiary.customerTypes.clinic}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={labelStyle}>Others</Typography>
                    <Typography size="md">
                      {getOthers(beneficiary.customerTypes)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {energyGenerationQuery.status === "loading" ? (
            skeleton
          ) : (
            <CarouselSlide peakLoads={energyGenerationQuery.data} />
          )}
        </Grid>
        <Grid item xs={12} lg={8} style={{ height: "auto" }}>
          {totalizerReadingsForChartQuery.status === "loading" ? (
            skeleton
          ) : (
            <Card style={{ background: COLORS.CARD_BACKGROUND }}>
              <CardContent>
                <Typography
                  style={{ color: "white", marginBottom: 6 }}
                  variant="h6"
                  component="h2"
                >
                  Select Date
                </Typography>
                <CustomDatePicker
                  handleDateChange={handleDateChange}
                  selectedDate={`${date.year}-${date.month + 1}-${date.day}`}
                />

                <TotalizerReadingCharts
                  data={totalizerReadingsForChartQuery?.data?.data}
                  setTotolizerChartOption={setTotolizerChartOption}
                  totolizerChartOption={totolizerChartOption}
                />
              </CardContent>
            </Card>
          )}
        </Grid>

        <LastReadingFromTotalizers />

        {/* <Grid item xs={12} lg={6} >
          {
            currentDataQuery.status === "loading" ? (<Skeleton variant='rect' width="40%" />) : (
              <CurrentChart currentData={currentDataQuery.data} />
            )
          }
        </Grid> */}
        <Grid item xs={12} lg={8} style={{ height: "auto" }}>
          {dailyGenerationDataQuery.status === "loading" ? (
            skeleton
          ) : (
            <Card style={{ background: COLORS.CARD_BACKGROUND }}>
              <CardContent>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ color: "white", marginBottom: 6 }}
                >
                  Ageto Controller Recording
                </Typography>
                <Typography
                  style={{ color: "gray", marginBottom: 6 }}
                  variant="h6"
                  component="h6"
                >
                  Select Date
                </Typography>
                <CustomDatePicker
                  handleDateChange={handleAgetoDateChange}
                  selectedDate={genDataDate}
                />
                <AgetoControllerRecord
                  dailyEnergyRecord={dailyGenerationDataQuery?.data?.data}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
        <LastReadingFromAgeto />
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{ color: "white" }}
              htmlFor="outlined-year-native-simple"
            >
              Select Year
            </InputLabel>
            <Select
              style={{ color: "white" }}
              labelId="outlined-year-native-simple-label"
              id="outlined-year-native-simple"
              value={selectedYear}
              onChange={handleListItemClick}
              label="Year"
            >
              {years.map((year) => {
                return (
                  <MenuItem key={year.id} value={year}>
                    {year.year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={6}>
            {currentExchangeRateResult.status === "loading" ? (
              <Skeleton variant="text" width="40%" />
            ) : (
              <Chip
                label={`1 USD = ${currentExchangeRateResult.data.buyingRate} LRD`}
                className={classes.clipStyle}
              />
            )}
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={6}>
          {yearlyEnergyGenerationQuery.status === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.charts2}>
              <CardContent>
                <YearlyEnergyChart
                  year={selectedYear}
                  generatedEnergies={yearlyEnergyGenerationQuery.data}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
        <Hidden smUp>
          <Grid item xs={6}>
            {currentExchangeRateResult.status === "loading" ? (
              <Skeleton variant="text" width="40%" />
            ) : (
              <Chip
                label={`1 USD = ${currentExchangeRateResult.data.buyingRate} LRD`}
                className={classes.clipStylexs}
              />
            )}
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={6}>
          {salesDataStatus === "loading" ||
          currentExchangeRateResult.status === "loading" ? (
            skeleton
          ) : (
            <Card className={classes.charts2}>
              <CardContent>
                <YearlySalesChart
                  year={selectedYear}
                  yearlySales={salesData}
                  rate={currentExchangeRateResult.data.buyingRate}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
        {/* <EnergyData
          classes={classes} /> */}

        <Grid item xs={12} lg={6} style={{ height: "auto" }}>
          {energyConsumptionResult.status === "loading" ? (
            skeleton
          ) : (
            <Card style={{ background: COLORS.CARD_BACKGROUND }}>
              <CardContent>
                <ConsumptionPerCustomerTypeChart
                  consumptions={energyConsumptionResult.data}
                />
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item xs={12} lg={6} style={{ height: "auto" }}>
          {totalConsumptionResult.status === "loading" ? (
            skeleton
          ) : (
            <Card style={{ background: COLORS.CARD_BACKGROUND }}>
              <CardContent>
                <TotalConsumptionChart
                  totalConsumptions={totalConsumptionResult.data}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;

const getOthers = (customerTypes) => {
  const { residential, business, school, church, clinic, ...rest } =
    customerTypes;
  let otherSum = 0;
  for (const prop in rest) {
    const value = customerTypes[prop];
    otherSum += value;
  }
  return otherSum;
};
