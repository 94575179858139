import React from "react";
import EnergyUsage from '../Components/Energy/EnergyUsage';
import CommercialData from '../Components/Commercial/CommercialData';
import CustomersMap from '../Components/Map/CustomersMap';
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MapIcon from '@material-ui/icons/Map';
import DataUsageIcon from "@material-ui/icons/DataUsage";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Dashboard from "../Components/Dashboard/Dashboard";
import Users from "../Components/User/Users";
import CustomerList from "../Components/CustomerList";

export const appUserRoles = ["User", "Admin", "Developer"];

export const superUserRoles = ['Admin', 'Developer'];

export const SubRoutes = [
  {
    path: "/app/dashboard",
    sidebarName: "Dashboard",
    sidebarIcon: <DashboardIcon />,
    component: Dashboard,
    roles:appUserRoles
  },
  {
    path: "/app/energy-usage",
    sidebarName: "Energy Usage",
    sidebarIcon: <DataUsageIcon />,
    component: EnergyUsage,
    roles:appUserRoles
  },
  {
    path: "/app/commercial-data",
    sidebarName: "Commercial Data",
    sidebarIcon: <MonetizationOnOutlinedIcon />,
    component: CommercialData,
    roles:appUserRoles
  },
  { 
    path: "/app/customers-list",
    sidebarName: "Customers List",
    sidebarIcon: <PeopleAltIcon />,
    component: CustomerList,
    roles:appUserRoles
  },
  {
    path: "/app/customers-map",
    sidebarName: "Customers Map",
    sidebarIcon: <MapIcon />,
    component: CustomersMap,
    roles:appUserRoles
  },
  {
    path: "/app/users",
    sidebarName: "Users",
    sidebarIcon: <PeopleAltIcon />,
    component: Users,
    roles:superUserRoles
  },
]

