import { makeStyles, Table, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { COLORS } from '../Constants';

const useStyles = makeStyles(theme => (
    {
        table: {
            '& thead th': {
                fontWeight: '700',
                //backgroundColor: "#d6e0f0",
                color: COLORS.MENU_ITEM_INACTIVE_COLOR
            },
            '& tbody td': {
                fontWeight: '400',
                color: 'white'
            },
            '& tbody tr:hover': {
                backgroundColor: COLORS.ROOT_BACKGROUND,
                cursor: 'pointer'
            }
        },
        tablePagination: {
            color: 'white'
        }
    }
))

const useTable = (headCells, record, filterFn) => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        if ('status' in record && 'data' in record) {
            if (record.status === 'success')
                setData(record.data);
        }
        else
            setData(record);
    }, [record]);

    const pages = [25, 10, 5];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState()

    const TblContent = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    );

    const TblHead = props => {

        const handleSortRequest = (cellId) => {
            const isAsc = orderBy === cellId && 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }
        return (<TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        {headCell.disableSorting ? headCell.label :
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => { handleSortRequest(headCell.id) }}>
                                {headCell.label}
                            </TableSortLabel>
                        }
                    </TableCell>))}
            </TableRow>
        </TableHead>)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const TblPagination = () => (
        <TablePagination className={classes.tablePagination} component="div"
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            page={page}
            count={data.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />)

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const getComparator = (order, orderBy) => {
        return order === "desc" ?
            (a, b) => descendingComparator(a, b, orderBy) :
            (a, b) => -descendingComparator(a, b, orderBy);
    }

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return 1;
        }
        if (b[orderBy] > a[orderBy]) {
            return -1
        }
        return 0;
    }

    const recordsAfterPaging = () => { 
            
        return stableSort(filterFn.fn(data), getComparator(order, orderBy)).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }
    return {
        TblContent,
        TblHead,
        TblPagination,
        recordsAfterPaging
    }
}

export default useTable
