import React from 'react';


import ReactApexChart from "react-apexcharts";


const TotalSalesChart = ({salesData, totalSale}) => {   


    const series = [{
        name: 'Total Sales',
        data: salesData.map(sd=>sd.totalSale)
    }];
    const labels = salesData.map(sd=>{
        const date = new Date(sd.year, sd.month-1);
        const period = `${date.toLocaleString('default', { month: 'long', year: 'numeric' })}`;
        return period;
    });
    const options = {
        chart: {
            type: 'line',
            height: 280,
            sparkline: {
                enabled: true
            },
        },
        labels: labels,
        stroke: {
            curve: 'smooth',
            //curve: 'straight',
            width:'2'
        },
        colors: ["lightblue"],
        title: {
            text: `${totalSale.toLocaleString()} LRD`,
            offsetX: 30,
            style: {
                fontSize: '24px',
                color:'white',            
            }
        },
        subtitle: {
            text: 'Sales Total Since May 2018',
            offsetX: 30,
            style: {
                fontSize: '16px',
                color:'white',
                padding:10
            }
        },
        tooltip:{
            y:{
                formatter: function(y){
                    if(y!==undefined)
                     return y.toLocaleString() + " LRD";
                    return y;
                }
            }
        }
    }
    return (
        <div>
            <ReactApexChart height={280} options={options} series={series} type="line" />
        </div>
    )
}

export default TotalSalesChart
