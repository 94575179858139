import { GENERATIONCAPACITY } from "../../Constants";
export const getPeakLoads = (generatorData) => {
  const peakLoads = generatorData.map((gd) => gd.maxLoad);
  return peakLoads;
};
export const percentPeakLoad = (load) => {
  const { maxLoad } = load;
  if (maxLoad === undefined) return;
  const maxLoadPercent = parseFloat(
    ((100 * maxLoad.genTotalPower) / GENERATIONCAPACITY).toFixed(1)
  );
  return maxLoadPercent;
};
export const percentPeakLoad2 = (loadFigure) => {
  if (!loadFigure) return 0;
  const maxLoadPercent = parseFloat(
    ((100 * loadFigure) / GENERATIONCAPACITY).toFixed(1)
  );
  return maxLoadPercent;
};

export const calculateLoadFactor = (load, energyUsage) => {
  const { maxLoad } = load;
  const { kWhUsed, durationOfOperation } = energyUsage;
  if (maxLoad === undefined) return;
  const avgLoadPercent = parseFloat(
    ((100 * kWhUsed) / (maxLoad.genTotalPower * durationOfOperation)).toFixed(1)
  );
  return avgLoadPercent;
};

//get the number of hours in a month: month is in the format 2021-01
const getHoursInMonth = (month) => {
  const [year, monthStr] = month.split("-");
  const date = new Date(parseInt(year), parseInt(monthStr) - 1, 1);
  //for current month, compute for days that have passed
  if (date.getMonth() === new Date().getMonth()) {
    const daysInMonth = new Date().getDate();
    return daysInMonth * 24;
  }
  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  return daysInMonth * 24;
};

export const calculateLoadFactor2 = (loadFigure, period, kWhUsed) => {
  if (!loadFigure || !kWhUsed) return 0;
  const totalHours = getHoursInMonth(period);

  const avgLoadPercent = parseFloat(
    ((100 * kWhUsed) / (loadFigure * totalHours)).toFixed(1)
  );
  return avgLoadPercent;
};
