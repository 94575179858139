import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../Constants';
import ActionButton from '../Controls/ActionButton';
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme=>(
    {
        dialogWrapper:{
            padding:theme.spacing(2),
            position:'absolute',
            top: theme.spacing(5),
            background: COLORS.NAV_BACKGROUND
        }
    }
));

const Popup = (props) => {
    const {title, children, openPopup, setOpenPopup} = props;
    const classes = useStyles();

    return (
        <Dialog open={openPopup} maxWidth="md" classes = {{paper: classes.dialogWrapper}}>
            <DialogTitle>
                <div style={{display:'flex'}}>
                    <Typography variant = "h6" component="div" style={{flexGrow:1, color:'white'}}>
                        {title}
                    </Typography>
                    <ActionButton color="secondary" onClick={()=>setOpenPopup(false)}>
                        <CloseIcon/>
                    </ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default Popup
