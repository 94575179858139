import React, { createContext} from "react";
import API from '../api/API';
import { useQuery } from 'react-query';

export const PeakLoadsContext = createContext();

const fetchPeakLoads = async(key)=>{
    const result = await API.get('/peak-loads');
    const data = result.data.map(d=>{
        const {date} = d;
        const dateTime = new Date(date.year, date.month-1, date.day, date.hour, date.minute, date.second);
        return {dateTime, ...d};
    });
    return data;
}

const PeakLoadsContextProvider = ({children}) => {
    const peakLoadsResult = useQuery('peak-load', fetchPeakLoads, { refetchOnWindowFocus: false });
    return (
        <PeakLoadsContext.Provider value={
            {
                peakLoads:peakLoadsResult.data,
                peakLoadsStatus:peakLoadsResult.status
            }
        }>
            {children}
        </PeakLoadsContext.Provider>
    )
}

export default PeakLoadsContextProvider
