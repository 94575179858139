import { makeStyles } from "@material-ui/core";
import {COLORS, DRAWERWIDTH} from "../../Constants"
export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: COLORS.ROOT_BACKGROUND,
        //textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2, 4),
            width:`calc(100vw - ${DRAWERWIDTH+17}px)`,
        },
    },
    card: {
        backgroundColor: COLORS.CARD_BACKGROUND,
        width: '100%',
        minHeight: 200,
        borderRadius: 5,
        //display: 'flex',
        padding:5,
        color:'white',
        justifyContent: 'center',
    },
    subheader: {
        color: "#bcaaa4",
      },
      charts: {
        background: COLORS.CARD_BACKGROUND,
      },
    mainChartBody: {
        overflowX: "auto",
    },
    
}));