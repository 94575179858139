import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../Services/firestoreService';


const authContext = createContext();

export const useAuth = () => {
    return useContext(authContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    const login = async (email, password) => {
        try {
            setLoading(true);
            await auth.signInWithEmailAndPassword(email, password);
        } catch (error) {
            throw error;
        }
    }
    const logout = async () => {
        await auth.signOut();
    }
    const getUserCredential = async (user) => {
        if (!user) {
            setCurrentUser(user);
            return;
        }
        const cred = await user.getIdTokenResult();
        const { token, claims } = cred;
        const { email, name, role, isMember, meterSerial, phone_number, exp, auth_time, user_id } = claims;

        const now = new Date();
        const authDateTime = new Date(auth_time * 1000);
        const hours = (now - authDateTime) / (1000 * 60 * 60);
        if (hours > 168) {
            await logout();
            setCurrentUser(undefined)
            setLoading(false);
            return;
        }

        const userCredential = {
            token,
            displayName: name,
            email,
            phoneNumber: phone_number,
            role,
            isMember,
            meterSerial,
            authTime: auth_time,
            expirationTime: exp,
            userId: user_id
        }
        setCurrentUser(userCredential);
    }
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            await getUserCredential(user);
            setLoading(false);
        });
        return unsubscribe 
        // eslint-disable-next-line 
    }, [])

    const value = {
        currentUser,
        login,
        logout,
        loading
    }
    return (
        <authContext.Provider value={value} >
            {children}
        </authContext.Provider>
    )
}
