
export const users = [
    {
        uid: "145598ed-cfba-4f68-810e-bff6c34dbb59",
        email: 'amineen@nreca-intl.org',
        displayName:"Aaron Mineen",
        phoneNumber : '+231886532462',
        role:'User'
    },
    {
        uid: "e4b78153-b5d4-4747-959e-a2a76cd23e81",
        email: 'amineen64@gmail.com',
        displayName:"Aaron Mineen",
        phoneNumber : '+231775597263',
        role:'Developer'
    },    
    {
        uid: "2cd9b1d8-236f-4c27-8136-268f3a33696a",
        email: 'aaronmassaquoi8@gmail.com',
        displayName:"Aaron Massaquoi",
        phoneNumber : '+231776728658',
        role:'Admin'
    },
    {
        uid: "907e47ef-f54e-401f-ace3-70257860e3c1",
        email: 'larismendi@nreca-intl.org',
        displayName:"Luis Arismendi",
        phoneNumber : '+34639716465',
        role:'User'
    },
    {
        uid: "5c061732-a62b-49b8-bd7f-8e2519b74710",
        email: 'fbregh@nreca-intl.org',
        displayName:"Frank Bergh",
        phoneNumber : '+15137037543',
        role:'User'
    },
    {
        uid: "a5c5b106-4182-477c-8e81-4f4232be506a",
        email: 'kerkulahdson@gmail.com',
        displayName:"D-Sun Kerkulah",
        phoneNumber : '+2318866832065',
        role:'Guestuser'
    },
]
export const roles = [
    {id: 1, role : "User"},
    {id: 2, role : "Guestuser"},
    {id: 3, role : "Member"},
    {id: 4, role : "Admin"},
    {id: 5, role : "Developer"},
]