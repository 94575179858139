import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../Constants";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#686980",
    // [theme.breakpoints.up("lg")]: {
    //   //height: "100",
    // },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 4),
    },
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    background: COLORS.ROOT_BACKGROUND,
    margin:0
},
  widgetCard: {
    minHeight: 180,
    display: "flex",
    flexDirection: "column",
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  card: {
    background: COLORS.CARD_BACKGROUND,
    color: "white",
    minHeight: 200,
  },
  charts: {
    background: COLORS.CARD_BACKGROUND,
    padding: 16,
  },
  charts2: {
    background: COLORS.CARD_BACKGROUND,
    // color: "white",
  },
  subheader: {
    color: "#bcaaa4",
  },
  spinner: {
    display: "flex",
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  yearsGrid: {
    margin: "0 auto",
    justifyContent: "center",
    maxWidth: "80%",

  },
  yearContainer: {
    background: "#686980",
    borderRadius: 10,
    justifyContent: "center",
  },
  carousel:{
    textAlign: 'center',   
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clipStyle:{
    marginLeft:"60%",
    background:"#686980",
    color:'#fcfcfc'
  },
  clipStylexs:{
    background:"#686980",
    color:'#fcfcfc',
    marginLeft:"10%"
  },
  datePickerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  datePickerTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));