import { Typography } from '@material-ui/core';
import React from 'react'
import Button from '../Controls/Button';

const DeleteUserPromp = (props) => {
    const { userToDelete, yesOnClick, noOnClick } = props;
    return (
        <div>
            <Typography variant="subtitle1" component='div' style={{ color: 'white', marginBottom:16 }}>
                {`Are you sure you want to delete ${userToDelete.displayName}?`}
            </Typography>
            <div style={{margin:"0 auto", textAlign:"center"}}>
                <Button color="secondary" onClick={yesOnClick} text="Yes" />
                <Button color="primary" onClick={noOnClick} text="No" />
            </div>
        </div>
    )
}

export default DeleteUserPromp
