import {
  Collapse,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useAuth } from "../../Contexts/authContext";
import { useHistory } from "react-router-dom";
import { appUserRoles } from "../../Routes/Routes";
import BackgroundImage from "../../assets/pv_system_2.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    background: "#41425D",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    //set the top margin based on the screen size
    marginTop: "10vh",
    marginLeft: 0,
    marginRight: 0,
  },
  welcomeContainer: {
    color: "#265073",
    textAlign: "center",
    fontSize: "1.8rem",
  },
  welcomeText: {
    color: "#29ADB2",
  },
  overview: {
    maxWidth: "500px",
    color: "#e8e8e8",
  },
  backgroundImage: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat", // Add this line
    height: "100vh",
  },
  card: {
    backgroundColor: "#4D4C7D",
    margin: theme.spacing(2),
    borderRadius: "12px",
    color: "#F5F7F8",
  },
  cardContent: {
    textAlign: "left",
  },
  cardHeader: {
    color: "#E0F4FF",
    marginBottom: theme.spacing(2),
  },
}));

const landingPageItems = {
  welcomeText1: "Welcome to",
  welcomeText2: "Totota Electric Cooperative",
  overviewHeader: "Promoting Sustainable Development",
  overview:
    "Providing affordable, reliable, and safe electricity that benefits more than 18,000 people in rural Liberia.",
  numberOfMembers: 400,
  numberOfEmployees: 16,
  solarMinigridSize: "70 kW",
};

const LandingPage = () => {
  const { currentUser } = useAuth();
  const history = useHistory();

  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
    if (currentUser && appUserRoles.indexOf(currentUser.role) !== -1) {
      history.push("/app");
    }
    if (currentUser && currentUser.role === "Member") {
      history.push("/customer");
    }
  }, [currentUser, history]);
  return (
    <div className={`${classes.root} ${classes.backgroundImage}`}>
      <Header />
      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedHeight={50}
      >
        <div className={classes.welcomeContainer}>
          <h1>
            {landingPageItems.welcomeText1} <br />{" "}
            <span className={classes.welcomeText}>
              {landingPageItems.welcomeText2}
            </span>{" "}
          </h1>
        </div>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.cardHeader}
                >
                  {landingPageItems.overviewHeader}
                </Typography>
                <Typography variant="body1">
                  {landingPageItems.overview}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography
                  className={classes.cardHeader}
                  variant="h6"
                  component="h2"
                >
                  Members
                </Typography>
                <Typography variant="body1">
                  Serving {landingPageItems.numberOfMembers} households,
                  commercial establishments and public facilities with clean and
                  reliable electricity.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography
                  className={classes.cardHeader}
                  variant="h6"
                  component="h2"
                >
                  Generation System
                </Typography>
                <Typography variant="body1">
                  Solar-Diesel Hybrid System
                </Typography>
                <Typography variant="body1">
                  Capacity: {landingPageItems.solarMinigridSize}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Additional cards for other data */}
        </Grid>
      </Collapse>
    </div>
  );
};

export default LandingPage;
