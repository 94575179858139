import React from 'react';
import { useAuth } from '../Contexts/authContext';
import { Route, Redirect } from 'react-router-dom';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { COLORS } from '../Constants';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        '& > h3': {
            color: 'white'
        },
        minHeight: '100vh',
        background: COLORS.ROOT_BACKGROUND
    },
    spinner: {
        color: '#60f3fa'
    }
}))

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const classes = useStyles();
    const { currentUser, loading } = useAuth();

    if (loading)
        return (
            <div className={classes.root}>
                <CircularProgress classes={{ colorPrimary: classes.spinner }} />
                <h3>Checking User's Authentication</h3>
            </div>);
    return (
        <Route {...rest} render={props => {
            if (!!currentUser && roles && roles.indexOf(currentUser.role)!==-1)
                return <Component {...props} />
            else
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }} />
    )
}

export default PrivateRoute
