import { Button, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles(theme=>({
    root:{
        minWidth:0,
        margin:theme.spacing(0.5)
    },
    secondary:{
        // backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-label': {
            color:theme.palette.secondary.main
        }
    },
    primary:{
        // backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
            color:"#60f3fa"
        }
    }
}));

const ActionButton = (props) => {
    const classes = useStyles();
    const {color, children, onClick, ...other} = props;
    return (
        <Button onClick ={onClick} {...other} className = {`${classes.root} ${classes[color]}`}>
            {children}
        </Button>
    )
}

export default ActionButton
