import React, { useState } from "react";
import { COLORS } from "../../Constants";
import { useCustomerData } from "../../Contexts/customerDataContext";
import PageHeader from "../PageHeader/PageHeader";
import { useHistory } from "react-router-dom";
import { AccountCircle } from "@material-ui/icons";
import { Skeleton as MuiSkeleta } from "@material-ui/lab";
import { useAuth } from "../../Contexts/authContext";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  Snackbar,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import Skeleton from "../Controls/Skeleton";
import Button from "../Controls/Button";
import { Alert } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    background: COLORS.ROOT_BACKGROUND,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(3),
    },
  },
  headingWrapper: {
    display: "flex",
    "& .MuiFormControl-root": {
      width: "30%",
      height: "15%",
      margin: theme.spacing(2.5),
      backgroundColor: "#E8F0FE",
      borderRadius: "5px",
    },
  },

  heading: {
    display: "block",
    "& .MuiFormControl-root": {
      width: "90%",
      height: "15%",
      margin: theme.spacing(1.5),
      backgroundColor: "#E8F0FE",
      borderRadius: "5px",
    },
    "& .MuiButton-root": {
      marginLeft: theme.spacing(1.5),
    },
  },

  selectControl: {
    margin: theme.spacing(1),
  },
  gridContainer: {
    background: "#41425D",
    padding: theme.spacing(0.5),
    borderRadius: 10,
    margin: theme.spacing(1.5),
    maxWidth: "95%",
  },
  nonTecMember: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  card: {
    backgroundColor: COLORS.CARD_BACKGROUND,
    width: "98%",
    // minHeight: 200,
    height: 250,
    borderRadius: 5,
    //display: 'flex',
    padding: 5,
    color: "white",
    justifyContent: "center",
  },
  subheader: {
    color: "#bcaaa4",
  },
  addButton: {
    color: "#60f3fa",
    borderColor: "#60f3fa",
    marginTop: 10,
  },
}));

const CustomerDetails = () => {
  const classes = useStyles();
  const [meterReset, setMeterReset] = useState(false);
  const { meterSerial, customerQuery, transactionRecordQuery, resetMyMeter } =
    useCustomerData();

  const { currentUser } = useAuth();

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const handleMeterReset = async () => {
    await resetMyMeter(meterSerial, {
      onSuccess: () => {
        setMeterReset(true);
      },
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMeterReset(false);
  };

  const getMeterStatus = (operatingMode, lastEnergyCode) => {
    if (operatingMode === 0) return "OFF";
    if (operatingMode === 1) return "ON";
    if (operatingMode === 2) {
      if (lastEnergyCode === 0) return "AUTO(OFF)";
      else return "AUTO(ON)";
    }
  };
  const getMeterStatusColor = (operatingMode, lastEnergyCode) => {
    if (operatingMode === 0 || lastEnergyCode === 0) return "red";
    else return "#00F794";
  };

  const getAmount = () => {
    const amount = parseFloat(transactionRecordQuery.data.lastRechargeAmount);
    if (Number.isNaN(amount)) return "Not Avaliable";
    return `${amount} LRD`;
  };
  return (
    <>
      <div className={classes.root}>
        <Button
          variant="outlined"
          size="small"
          text="Back"
          type="button"
          onClick={goBack}
          className={classes.addButton}
          startIcon={<ArrowBackIcon />}
        />
        {customerQuery.status === "loading" || !customerQuery.data ? (
          <MuiSkeleta variant="text" width="70%" />
        ) : (
          <PageHeader
            title={`${customerQuery.data.customerName}`}
            subTitle={`Displays customer's credit balance, transaction and energy consumption records`}
            icon={<AccountCircle fontSize="large" />}
          />
        )}

        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} lg={3}>
            {customerQuery.status === "loading" || !customerQuery.data ? (
              <Skeleton />
            ) : (
              <Card className={classes.card}>
                <CardHeader
                  classes={{ subheader: classes.subheader }}
                  subheader="Credit Balance"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {parseFloat(
                      customerQuery.data.creditBalance.toFixed(2)
                    ).toLocaleString()}{" "}
                    <span style={{ fontSize: 14, font: "Helvetica" }}>LRD</span>
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Meter Status:  `}
                    <span
                      style={{
                        color: getMeterStatusColor(
                          customerQuery.data.operatingMode,
                          customerQuery.data.lastEnergyCode
                        ),
                      }}
                    >
                      {getMeterStatus(
                        customerQuery.data.operatingMode,
                        customerQuery.data.lastEnergyCode
                      )}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Last Synced:  ${customerQuery.data.lastSyncedTime} `}
                  </Typography>
                  {currentUser && (currentUser.role === "Admin" || currentUser.role === "Developer")  && (
                    <Button
                      text="Reset Meter"
                      className={classes.addButton}
                      variant="outlined"
                      size="small"
                      type="button"
                      onClick={handleMeterReset}
                      startIcon={<SyncIcon />}
                    />
                  )}
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            {transactionRecordQuery.status === "loading" ||
            !transactionRecordQuery.data ? (
              <Skeleton />
            ) : (
              <Card className={classes.card}>
                <CardHeader
                  classes={{ subheader: classes.subheader }}
                  subheader="Last Recharge"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {`Amount :  ${getAmount(
                      transactionRecordQuery.data.lastRechargeAmount
                    )}`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Meter Serial:  ${meterSerial}`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Recharge Time: ${new Date(
                      transactionRecordQuery.data.lastRechargeDate._seconds *
                        1000
                    ).toISOString()} `}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {customerQuery.status === "loading" ||
            !customerQuery.data ||
            transactionRecordQuery.status === "loading" ||
            !transactionRecordQuery.data ? (
              <Skeleton />
            ) : (
              <Card className={classes.card}>
                <CardHeader
                  classes={{ subheader: classes.subheader }}
                  subheader="Total Recharge"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {parseFloat(
                      transactionRecordQuery.data.totalPurchase.toFixed(0)
                    ).toLocaleString()}{" "}
                    <span style={{ fontSize: 14, font: "Helvetica" }}>LRD</span>
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Recharge Count:   ${transactionRecordQuery.data.rechargeCount}`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Last Energy Reading:   ${parseFloat(
                      customerQuery.data.lastEnergy.toFixed(2)
                    ).toLocaleString()} kWh`}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            {transactionRecordQuery.status === "loading" ||
            !transactionRecordQuery.data ? (
              <Skeleton />
            ) : (
              <Card className={classes.card}>
                <CardHeader
                  classes={{ subheader: classes.subheader }}
                  subheader="Additional Info"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {`Location:  ${transactionRecordQuery.data.meterInfo.street1}`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Coordinates:   ${transactionRecordQuery.data.meterInfo.coords}`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Males:   ${transactionRecordQuery.data.meterInfo.male}`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Females:   ${transactionRecordQuery.data.meterInfo.female}`}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {customerQuery.status === "loading" || !customerQuery.data ? (
              <Skeleton />
            ) : (
              <Card className={classes.card}>
                <CardHeader
                  classes={{ subheader: classes.subheader }}
                  subheader="Meter's Latest Readings"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {` Avgerage Load: ${parseFloat(
                      customerQuery.data.avgPower.toFixed(2)
                    ).toLocaleString()} W`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`This Month's Consumption:  ${parseFloat(
                      customerQuery.data.totalEnergyThisMonth.toFixed(2)
                    ).toLocaleString()}  kWh`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Max Current:  ${parseFloat(
                      customerQuery.data.maxCurrent.toFixed(2)
                    ).toLocaleString()}  A`}
                  </Typography>
                  <Typography variant="subtitle1" component="h5">
                    {`Voltage:  ${parseFloat(
                      customerQuery.data.maxVoltage.toFixed(0)
                    ).toLocaleString()}  V`}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
        <div>
          <Snackbar
            open={meterReset}
            autoHideDuration={6000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success" onClose={closeSnackbar}>
              Meter reset successful. It takes few minutes for the action to
              take effect.
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
