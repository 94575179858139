import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";

const formatNumber = (number, decimals = 1, unit = "") => {
  return number.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,") + unit;
};

const AgetoRecordCardItem = ({ title, figure, unit = " kWh" }) => {
  return (
    <Card
      style={{
        backgroundColor: "#41425D",
        borderRadius: "12px",
        borderColor: "#41425D",
      }}
    >
      <CardContent>
        <Typography variant="subtitle1" style={{ color: "#bcaaa4" }}>
          {title}
        </Typography>
        <Typography variant="body1" style={{ color: "white" }}>
          {formatNumber(figure, 1, unit)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AgetoRecordCardItem;
