import React from 'react';
import { Switch, useRouteMatch } from "react-router-dom";
import { CustomerBalanceProvider } from '../../Contexts/customerBalanceContext';
import PrivateRoute from '../../Routes/PrivateRoute';
import { appUserRoles } from '../../Routes/Routes';
import CustomerDetails from './CustomerDetails';
import CustomerTable from './CustomerTable';

const CustomerList = () => {
  const {path} = useRouteMatch();
  return (
    <Switch>
        <CustomerBalanceProvider>
          <PrivateRoute
            roles={appUserRoles}
            path={path}
            exact
            component={CustomerTable}
          />
          <PrivateRoute
            roles={appUserRoles}
            path={`${path}/customer-details`}
            exact
            component={CustomerDetails}
          />
        </CustomerBalanceProvider>
    </Switch>
  )
}

export default CustomerList