import React from 'react'

import ReactApexChart from "react-apexcharts";
import { sumBy } from 'lodash';

const YearlyEnergyChart = ({year, generatedEnergies }) => {
  const totalkWH = sumBy(generatedEnergies, ge=>ge.energyGeneration);
  const series = [{ name: "kWH", data: generatedEnergies.map(ge => parseFloat(ge.energyGeneration.toFixed(2))) }];
  const options = {
    chart: {
      type: 'area',
      foreColor: "#ccc",
      zoom: {
        enabled: false
      }
    },
    colors: ["#00BAEC"],
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0
      }
    },

    markers: {
      size: 2,
      colors: ["#000524"],
      strokeColor: "#00BAEC",
      strokeWidth: 1
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: `Total: ${totalkWH.toLocaleString()} kWh`,
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      }
    },
    subtitle: {
      text: `${year.year} Generated kWh`,
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      }
    },
    stroke: {
      curve: 'smooth',
      width: 1
    },
    tooltip: {
      theme: "light"
    },
    labels: generatedEnergies.map(ge => ge.period),
    yaxis: {
      show: true
    },
  }

  return (
    <div>
      <ReactApexChart height={400} options={options} series={series} type="area" />
    </div>
  )
}

export default YearlyEnergyChart
