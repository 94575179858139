import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PeakLoadChart from '../../Charts/PeakLoadChart';


const Carousel = ({ peakLoad }) => {
    const { year, month } = peakLoad;
    const date = new Date(year, month-1, 1);
    const period = `${date.toLocaleString('default', { month: 'long' })} ${year}: ${peakLoad.peakLoad.toFixed(1)} kW`;

    const loadingPercentage = parseFloat((100*peakLoad.peakLoad/70).toFixed(1));


    const useStyles = makeStyles(() => ({
        card: {
            backgroundColor: '#2B2C41',
            width: '100%',
            minHeight: 200,
            borderRadius: 5,
            //display: 'flex',
            padding:5,
            justifyContent: 'center',
        }
    }));


    const classes = useStyles();
    return (
        <div className={classes.card}>
            <Typography
                variant="caption"
                style={{
                    color: "#bcaaa4",
                    fontSize: 16,
                }}
            >
                Peak Load
            </Typography>
            <h3 style={{
                    color: "#bcaaa4"
                }}>{period}</h3>
            <PeakLoadChart peakLoad={loadingPercentage}/>
        </div>
    )
}

export default Carousel
