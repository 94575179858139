import React, { useContext, useEffect, useState } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import GroupIcon from '@material-ui/icons/Group';
import useTable from '../useTable';
import { InputAdornment, makeStyles, Paper, TableBody, TableCell, TableRow, Toolbar } from '@material-ui/core';
import { COLORS } from '../../Constants';
import UserForm from './UserForm';
import { UsersContext } from '../../Contexts/usersContext';
import Input from '../Controls/Input';
import { Search } from '@material-ui/icons';
import Button from '../Controls/Button';
import AddIcon from '@material-ui/icons/Add';
import Popup from '../Controls/Popup';
import ActionButton from '../Controls/ActionButton';
import EditOutlinedIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import EditUserForm from './EditUserForm';
import DeleteUserPromp from './DeleteUserPromp';

const useStyles = makeStyles(theme => ({
    pageContent: {
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(5),
            padding: theme.spacing(3),
            backgroundColor: COLORS.NAV_BACKGROUND,

        },
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(1.5),
            padding: theme.spacing(1),
            backgroundColor: 'transparent',
        },
    },
    toolbar: {
        '& .MuiTextField-root': {
            width: '75%',
            margin: theme.spacing(1),
            backgroundColor: '#E8F0FE',
            borderRadius: '5px'
        },
        '& .MuiInputBase-input': {
            padding: '12px 12px 12px 0'
        },
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        },
    },
    addButton: {
        [theme.breakpoints.up("sm")]: {
            position: 'absolute',
            right: '10px',
        },
        color: '#60f3fa',
        borderTopColor: '#60f3fa',
        borderRightColor: '#60f3fa',
        borderLeftColor: '#60f3fa',
        borderBottomColor: '#60f3fa',
    }

}));
const headCells = [
    { id: 'displayName', label: "Display Name" },
    { id: 'email', label: "Email Address" },
    { id: 'phoneNumber', label: "Phone Number" },
    { id: 'isMember', label: "TEC Member?" },
    { id: 'role', label: "User Role" },
    { id: 'actions', label: "Actions", disableSorting: true },
]
const Users = () => {

    const { usersDataQuery, userEdited, deleteUser } = useContext(UsersContext);
    const [openNewUserForm, setOpenNewUserForm] = useState(false);
    const [openEditUserForm, setOpenEditUserForm] = useState(false);
    const [openDeleteUserPopup, setOpenDeleteUserPopup] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

    const { TblContent, TblHead, TblPagination, recordsAfterPaging } =
        useTable(headCells, usersDataQuery, filterFn);
    const classes = useStyles();
    const handleAddNewClick = (e) => {
        e.preventDefault();
        setOpenNewUserForm(true);
    }
    useEffect(() => {
        if (userEdited) {
            setOpenEditUserForm(false);
        }
    }, [userEdited])
    const editUser = (item) => {
        const { uid, displayName: fullName, email, phoneNumber, customClaims } = item;
        const role = customClaims.role;
        const isMember = !customClaims.isMember ? false : customClaims.isMember;
        const meters = customClaims.meterSerial;

        setUserToEdit({ uid, fullName, email, phoneNumber, role, isMember, meters });
        setOpenEditUserForm(true);
    }
    const prompToDeleteUser = (item) => {
        if (!item)
            return;
        setUserToDelete(item);
        setOpenDeleteUserPopup(true);
    }

    const yesToDelete = async () => {
        await deleteUser(userToDelete.uid, {
            onSuccess: () => {
                setOpenDeleteUserPopup(false);
            }
        });
    }

    const noToDelete = () => {
        setOpenDeleteUserPopup(false);
    }
    const handleSearch = (event) => {
        const target = event.target;
        setFilterFn({
            fn: items => {
                if (target.value === "") return items;
                else {
                    return items.filter(x => x.displayName.toLowerCase().includes(target.value) || x.email.includes(target.value) || x.phoneNumber.includes(target.value));
                }
            }
        })
    }
    return (
        <>
            <PageHeader title="Users Page"
                subTitle="List of users for TEC Dashboard App"
                icon={<GroupIcon fontSize="large" />}
            />
            {/* <Paper className={classes.pageContent} >
                <UserForm />

            </Paper> */}
            {usersDataQuery.status === "loading" ? null :
                <Paper className={classes.pageContent}>
                    <Toolbar className={classes.toolbar}>
                        <Input label="Search"
                            name="search"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (<InputAdornment position='start'>
                                    <Search />
                                </InputAdornment>)
                            }}
                        />
                        <Button text="Add New"
                            className={classes.addButton}
                            variant="outlined"
                            size="large"
                            onClick={handleAddNewClick}
                            startIcon={<AddIcon />}
                        />
                    </Toolbar>
                    <TblContent>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPaging().map(item => (
                                    <TableRow key={item.uid}>
                                        <TableCell>{item.displayName}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.phoneNumber}</TableCell>
                                        <TableCell>{item.customClaims.isMember.toString()}</TableCell>
                                        <TableCell>{item.customClaims.role}</TableCell>
                                        <TableCell>
                                            <ActionButton color="primary" onClick={() => editUser(item)}>
                                                <EditOutlinedIcon fontSize="small" />
                                            </ActionButton>
                                            <ActionButton color="secondary" onClick={() => prompToDeleteUser(item)}>
                                                <CloseIcon fontSize="small" />
                                            </ActionButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </TblContent>
                    <TblPagination />
                </Paper>
            }
            <Popup openPopup={openNewUserForm} setOpenPopup={setOpenNewUserForm} title="User Form">
                <UserForm />
            </Popup>
            <Popup openPopup={openEditUserForm} setOpenPopup={setOpenEditUserForm} title="Edit User Form">
                <EditUserForm userToEdit={userToEdit} />
            </Popup>
            <Popup title="Delete User?" openPopup={openDeleteUserPopup} setOpenPopup={setOpenDeleteUserPopup} >
                <DeleteUserPromp yesOnClick={yesToDelete} noOnClick={noToDelete} userToDelete={userToDelete} />
            </Popup>
        </>
    )
}

export default Users
