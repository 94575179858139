import React, { createContext, useState } from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import API from "../api/API";
import { roles } from "../Services/userServices";


export const UsersContext = createContext();

const fetchUsers = async (key) => {
    const result = await API.get('/users');
    return result.data
}
const createUser = async (user) => {
    const { role } = roles.find(r => r.id === user.role);
    const { fullName: displayName, email, phoneNumber, password, isMember } = user;
    let { meters: meterSerial } = user;
    if (!isMember) meterSerial = [];
    const newUser = { displayName, email, phoneNumber, password, role, isMember, meterSerial };
    const result = await API.post('/users', newUser
    );
    return result.data;
}
const editUser = async (user) => {
    const { role } = roles.find(r => r.id === user.role);
    const { uid, fullName: displayName, email, phoneNumber, isMember } = user;
    let { meters: meterSerial } = user;
    if (!isMember) meterSerial = [];
    const updatedUser = { uid, displayName, email, phoneNumber, role, isMember, meterSerial };
    const result = await API.patch(`/users/${uid}`, updatedUser);
    return result.data;
}
const deleteGivenUser = async (uid) => {
    const result = await API.delete(`/users/${uid}`);
    return result.data;
}
export const UsersProvider = ({ children }) => {

    const [userEdited, setUserEdited] = useState(false);
    const queryClient = useQueryClient();

    const usersDataQuery = useQuery("users", fetchUsers, { refetchOnWindowFocus: false });

    const { mutateAsync } = useMutation(createUser, {
        onSuccess: (newUser) => {
            queryClient.setQueryData("users", (users) => [...users, newUser])
        }
    });

    const { mutateAsync: deleteUser } = useMutation('users', deleteGivenUser, {
        onSuccess: (uid) => {
            queryClient.removeQueries('users', uid);
        }
    });
    const { mutateAsync: updateUser } = useMutation(editUser, {
        onSuccess: (editedUser) => {
            setUserEdited(true);
            queryClient.setQueryData('users', users => users.map(user => {
                if (user.uid === editedUser.uid)
                    user = editedUser;
                return user;
            }))
        }
    })
    return (
        <UsersContext.Provider value={{
            usersDataQuery,
            mutateAsync,
            updateUser,
            userEdited,
            setUserEdited,
            deleteUser,
        }}>
            {children}
        </UsersContext.Provider>
    )
}