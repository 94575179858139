import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
//return 12 kWh
const formatNumber = (number, decimals = 1, unit = "") => {
  if (!number) return null;
  return number.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,") + unit;
};

const DailyTotalizerSummary = ({ dailykWh, peakLoad, date }) => {
  if (!date || !dailykWh || !peakLoad) return null;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Card
            style={{
              backgroundColor: "#41425D",
              borderRadius: "12px",
              borderColor: "#41425D",
            }}
          >
            <CardContent>
              <Typography variant="subtitle1" style={{ color: "#bcaaa4" }}>
                Energy Generated
              </Typography>
              <Typography variant="body1" style={{ color: "white" }}>
                {formatNumber(dailykWh, 1, " kWh")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Card
            style={{
              backgroundColor: "#41425D",
              borderRadius: "12px",
              borderColor: "#41425D",
            }}
          >
            <CardContent>
              <Typography variant="subtitle1" style={{ color: "#bcaaa4" }}>
                Peak Avg Load
              </Typography>
              <Typography variant="body1" style={{ color: "white" }}>
                {formatNumber(peakLoad / 1000, 1, " kW")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DailyTotalizerSummary;
