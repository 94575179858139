import React from "react";
import Skeleton from "../Controls/Skeleton";
import FigureComponent from "./FigureComponent";
import { useLatestReadingContext } from "../../Contexts/latestReadingContext";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { COLORS } from "../../Constants";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: COLORS.CARD_BACKGROUND,
    width: "98%",
    minHeight: 200,
    borderRadius: 5,
    //display: 'flex',
    padding: 5,
    color: "white",
    justifyContent: "center",
  },
  subheader: {
    color: "#bcaaa4",
  },
  addButton: {
    color: "#60f3fa",
    borderColor: "#60f3fa",
    marginTop: 10,
  },
}));

const LastReadingFromAgeto = () => {
  const { lastAgetoReadingQuery } = useLatestReadingContext();

  const formatNumber = (number, decimals = 1) => {
    if (number === undefined) return 0;
    return number.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const classes = useStyles();

  return (
    <Grid item xs={12} lg={4}>
      {lastAgetoReadingQuery.status === "loading" ? (
        <Skeleton />
      ) : (
        <Card className={classes.card}>
          <CardHeader
            classes={{ subheader: classes.subheader }}
            subheader="Last Readings from Ageto Controller"
          />
          <CardContent>
            <FigureComponent
              figure={formatNumber(
                lastAgetoReadingQuery?.data?.data?.loadTotalPower
              )}
              title="Total Load: "
              unit="kW"
              color="#00DFA2"
            />
            <FigureComponent
              figure={formatNumber(
                lastAgetoReadingQuery?.data?.data?.pvTotalPower
              )}
              title="PV Total Power:  "
              unit="kW"
              color="#00DFA2"
            />
            <FigureComponent
              figure={formatNumber(
                lastAgetoReadingQuery?.data?.data?.bmsTotalPower
              )}
              title={
                lastAgetoReadingQuery?.data?.data?.bmsTotalPower > 0
                  ? "Battery Charging:  "
                  : "Battery Discharging:  "
              }
              unit="kW"
              color="#00DFA2"
            />
            <FigureComponent
              figure={formatNumber(
                lastAgetoReadingQuery?.data?.data?.genTotalPower
              )}
              title="CAT Genset: "
              unit="kW"
              color="#00DFA2"
            />
            <FigureComponent
              figure={formatNumber(lastAgetoReadingQuery?.data?.data?.bmsSOC)}
              title="Battery SOC: "
              unit="%"
              color="#00DFA2"
            />

            <Typography
              variant="body2"
              style={{ color: "#B4B4B3" }}
              component="h5"
            >
              {`Last Reading Time:   ${lastAgetoReadingQuery?.data?.data?.timestamp} `}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default LastReadingFromAgeto;
