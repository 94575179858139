import { chain, sumBy } from 'lodash';
import React from 'react';


import ReactApexChart from "react-apexcharts";


const ConsumptionPerCustomerTypeChart = ({ consumptions }) => {
    const consumptionData = chain(consumptions).groupBy(cd => cd.customerType).value();
    const series = [
        {
            name: 'Businesses',
            data: consumptionData['Business'].map(cd => cd.TotalkWhConsumed)
        },
        {
            name: 'Health',
            data: consumptionData['Health'].map(cd => cd.TotalkWhConsumed)
        },
        {
            name: 'Households',
            data: consumptionData['Household'].map(cd => cd.TotalkWhConsumed)
        },
        {
            name: 'Institutions',
            data: consumptionData['Institution'].map(cd => cd.TotalkWhConsumed)
        }
    ];
    const labels = consumptionData['Household'].map(c => {
        const date = new Date(c.year, c.month - 1);
        const thisPeriodConsumptions = consumptions.filter(cs=>cs.year === c.year && cs.month === c.month);
        const totalConsumptionPerPeriod = sumBy(thisPeriodConsumptions, csp=>csp.TotalkWhConsumed);
        const period = `${date.toLocaleString('default', { month: 'long', year: 'numeric' })} (${parseFloat(totalConsumptionPerPeriod.toFixed(1)).toLocaleString()} kWh)`;
        return period;
    });
    const options = {
        chart: {
            type: 'line',
            height: 300,
            sparkline: {
                enabled: true
            },
        },
        labels: labels,
        yaxis: {
            show: true
          },
        stroke: {
            curve: 'smooth',
            //curve: 'straight',
            width: '2'
        },

        legend: {
            show: true,
            position: 'top',
            floating: true,
            offsetY: -20,
            offsetX: 0,
            labels: {
                colors: 'white',
            },
            horizontalAlign: 'left',
        },
        title: {
            text: `kWh Consumption`,
            offsetX: 20,
            offsetY:-5,
            style: {
                fontSize: '15px',
                color: 'white',
            }
        },

        tooltip: {
            y: {
                formatter: function (y) {
                    if (y !== undefined)
                        return y.toLocaleString() + " kWh";
                    return y;
                }
            }
        }
    }
    return (
        <div>
            <ReactApexChart height={300} options={options} series={series} type="line" />
        </div>
    )
}

export default ConsumptionPerCustomerTypeChart
