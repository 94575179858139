import { sum } from "lodash";
import React from "react";
import ReactApexChart from "react-apexcharts";

const TotalConsumptionChart = ({ totalConsumptions
}) => {
    
    let series = totalConsumptions.map(tc=>tc.totalConsumption);
    let labels = totalConsumptions.map(tc=>{
        if(tc.customerType==="Health")
            return tc.customerType;
        if(tc.customerType==="Business")
            return tc.customerType + 'es';
        return tc.customerType+'s'
    });
    const total = sum(series);
    const options = {
        chart: {
            height: 300,
            type: 'donut',
        },
        labels: labels,
        
        title: {
            text: `${parseFloat(total.toFixed(1)).toLocaleString()} kWh`,
            offsetX: 30,
            style:{
                color:'white',
                fontSize: '18px',
            }           
        },
        subtitle: {
            text: 'Total kWh Consumed Since May 2018',
            offsetX: 30,
            style: {
                fontSize: '14px',
                color:'white',
                padding:5
            }
        },
        tooltip: {
            y: {
                formatter: function (y) {
                    if (y !== undefined)
                        return parseFloat(y.toFixed(1)).toLocaleString() + " kWh";
                    return y;
                }
            }
        },
        dataLabels: {
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, val.toFixed(1) + '%']
            }
        },
        legend: {
            show: true,
            labels: {
                colors: 'white',
            },
        }
    };

    return (
        <div >
            <ReactApexChart options={options} height={300} series={series} type="donut" />
        </div>
    )
}

export default TotalConsumptionChart

