import React from 'react';
import { Skeleton as MuiSkeleta } from "@material-ui/lab";

const Skeleton = () => {
    return (
        <>
            <MuiSkeleta variant='text' width="100%" />
            <MuiSkeleta variant='text' width="70%" />
            <MuiSkeleta variant='circle' width={40} height={40} />
            <MuiSkeleta variant='rect' width={400} height={200} />
        </>
    )
}

export default Skeleton
