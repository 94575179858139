import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import React from "react";
import Button from "../Controls/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appbar: {
    color: "none",
  },
  appbarTitle: {
    flexGrow: 1,
    fontSize: "2rem",
  },
  signinButton: {
    color: "#60f3fa",
    borderColor: "#60f3fa",
    "&:hover": {
      borderColor: "#60f3fa",
      color: "#fff",
    },
    //nowrapping the text
    whiteSpace: "nowrap",
  },
}));

const Header = () => {
  const history = useHistory();
  const handleSignIn = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  const classes = useStyles();
  return (
    <AppBar className={classes.appbar} elevation={0}>
      <Toolbar>
        <h1 className={classes.appbarTitle}>Totota Electric Cooperative</h1>
        <Button
          variant="outlined"
          className={classes.signinButton}
          onClick={handleSignIn}
          size="large"
          text="Sign in"
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
