import React from "react";
import DatePicker from "react-datepicker";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "18px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "18px",
      },
    },
  },
}));

const CustomInput = React.forwardRef(({ value, onClick, onChange }, ref) => {
  const [inputValue, setInputValue] = React.useState(value);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onChange(event);
    }
  };

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextField
      className={useStyles().root}
      variant="outlined"
      onFocus={onClick}
      size="small"
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      inputRef={ref}
    />
  );
});
//create date from string "2021-01-01"
const getDateFromString = (dateString) => {
  const dateArr = dateString.split("-");
  const date = new Date(
    parseInt(dateArr[0]),
    parseInt(dateArr[1]) - 1,
    parseInt(dateArr[2])
  );
  return date;
};
const CustomDatePicker = ({ selectedDate, handleDateChange }) => {
  const [date, setDate] = React.useState(getDateFromString(selectedDate));

  const handleInputChange = (event) => {
    setDate(new Date(event.target.value));
    handleDateChange(new Date(event.target.value));
  };

  const handleDatePick = (pickedDate) => {
    setDate(pickedDate);
    handleDateChange(pickedDate);
  };

  return (
    <DatePicker
      selected={date}
      onChange={handleDatePick}
      customInput={
        <CustomInput
          value={date.toISOString().split("T")[0]}
          onChange={handleInputChange}
        />
      }
    />
  );
};
export default CustomDatePicker;
