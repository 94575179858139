import React from "react";

import { withRouter, Switch, Link, useHistory, Redirect } from "react-router-dom";

import EnergyDataProvider from '../../Contexts/energyDataContext';
import PeakLoadsContextProvider from "../../Contexts/peakLoadContext";
import { GeneratorDataProvider } from "../../Contexts/generatorDataContext";
import { CommercialDataProvider } from "../../Contexts/commercialDataContext";
import { UsersProvider } from "../../Contexts/usersContext";
import { CustomersMapDataProvider } from "../../Contexts/customersMapsDataContext.";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { SubRoutes, appUserRoles, superUserRoles } from "../../Routes/Routes";
import { MenuList, MenuItem, Button } from "@material-ui/core";
import { COLORS, DRAWERWIDTH } from "../../Constants";
import Users from "../User/Users";
import PrivateRoute from "../../Routes/PrivateRoute";
import Dashboard from "../Dashboard/Dashboard";
import EnergyUsage from "../Energy/EnergyUsage";
import CommercialData from "../Commercial/CommercialData";
import CustomersMap from "../Map/CustomersMap";
import { useAuth } from '../../Contexts/authContext';
import UserIcon from "../Controls/UserIcon";
import UsersRoute from "../../Routes/UsersRoute";
import logo from "../../nreca-logo.png"
import LatestReadingDataProvider from "../../Contexts/latestReadingContext";
import CustomerList from "../CustomerList";
const drawerWidth = DRAWERWIDTH;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logoImg: {
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: "#3C779C",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: COLORS.NAV_BACKGROUND,
    color: "white",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
    },
    // [theme.breakpoints.down("sm")]: {
    //   padding: theme.spacing(3),
    // },
    width: "100%"
  },
  usersButton: {
    width: '100%',
    margin: '0 auto',
    color: COLORS.MENU_ITEM_INACTIVE_COLOR
  },
  usersButtonLabel: { textTransform: 'none' }
}));


const NavBar = (props) => {

  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const navigateToUserMeterPage = e => {
    if (currentUser && currentUser.isMember)
      history.push('/customer');
  }
  const activeRoute = (routeName) => {
    return props.location.pathname === routeName ? true : false;
  };
  const classes = useStyles(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const menuItemInactiveColor = COLORS.MENU_ITEM_INACTIVE_COLOR;
  const menuItemActiveColor = COLORS.MENU_ITEM_ACTIVE_COLOR;

  const drawer = (
    <div>
      <img src={logo} alt="Logo" className={classes.logoImg} />
      {/* <div className={classes.toolbar} /> */}
      <Divider />
      <MenuList>
        {SubRoutes.map((prop, key) => {
          if (currentUser && prop.roles.indexOf(currentUser.role) !== -1)
            return (
              <Link
                to={prop.path}
                style={{
                  textDecoration: "none",
                  color: activeRoute(prop.path)
                    ? menuItemActiveColor
                    : menuItemInactiveColor,
                }}
                key={key}
              >
                <MenuItem selected={activeRoute(prop.path)}>
                  <ListItemIcon
                    style={{
                      color: activeRoute(prop.path)
                        ? menuItemActiveColor
                        : menuItemInactiveColor,
                    }}
                  >
                    {prop.sidebarIcon}
                  </ListItemIcon>
                  <ListItemText primary={prop.sidebarName} />
                </MenuItem>
              </Link>
            )
          else
            return null;
        })
        }
      </MenuList>
      {
        (currentUser && currentUser.isMember) ?
          (
            <>
              <Divider />
              <Button onClick={navigateToUserMeterPage}
                classes={{
                  root: classes.usersButton,
                  label: classes.usersButtonLabel
                }}>My Meter</Button>
              <Divider />
            </>
          ) : null
      }
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            size="medium"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title} noWrap>
            Totota Electric Cooperative
          </Typography>
          {
            currentUser && (
              <UserIcon currentUser={currentUser} logout={logout} />
            )
          }
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <LatestReadingDataProvider>
          <PeakLoadsContextProvider>
            <CommercialDataProvider>
              <EnergyDataProvider>

                <GeneratorDataProvider>
                  <CustomersMapDataProvider>
                    <Switch style={{ marginLeft: 24 }}>
                      <PrivateRoute roles={appUserRoles} path="/app/dashboard" exact component={Dashboard} />
                      <Redirect exact from="/app" to="/app/dashboard" />
                      <PrivateRoute roles={appUserRoles} path="/app/energy-usage" component={EnergyUsage} />
                      <PrivateRoute roles={appUserRoles} path="/app/commercial-data" component={CommercialData} />
                      <PrivateRoute roles={appUserRoles} path="/app/customers-map" component={CustomersMap} />
                      <PrivateRoute  roles = {appUserRoles} path="/app/customers-list" component={CustomerList} />
                      <UsersProvider>
                        <UsersRoute roles={superUserRoles} path="/app/users" component={Users} />
                      </UsersProvider>
                    </Switch>
                  </CustomersMapDataProvider>
                </GeneratorDataProvider>

              </EnergyDataProvider>
            </CommercialDataProvider>
          </PeakLoadsContextProvider>
        </LatestReadingDataProvider>

      </main>
    </div>
  );
};

export default withRouter(NavBar);
