import React from "react";
import { Route, Switch } from 'react-router-dom';

import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { COLORS } from "./Constants";
import { AuthProvider } from './Contexts/authContext'
import LandingPage from "./Components/Home/LandingPage";
import Login from "./Components/UserLogin/Login";
import NavBar from "./Components/Navigation/NavBar";
import PrivateRoute from "./Routes/PrivateRoute";
import { appUserRoles } from './Routes/Routes';
import Customer from "./Components/Customers/Customer";
import { CustomerDataProvider } from "./Contexts/customerDataContext";
import MembersRoute from "./Routes/MembersRoute";


const theme = createMuiTheme({
  palette: {
    background: {
      default: COLORS.ROOT_BACKGROUND
    }
  }
});
function App() {

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CustomerDataProvider>
          <div className='App'>
            <Switch>
              <PrivateRoute roles={appUserRoles} path="/app" component={NavBar} />
              <MembersRoute path="/customer" component={Customer} />
              <Route path="/" exact component={LandingPage} />
              <Route path="/login" component={Login} />
            </Switch>
          </div>
        </CustomerDataProvider>
      </AuthProvider>
    </ThemeProvider>

  );
}

export default App;
