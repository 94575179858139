import { Checkbox as MuiCheckBox, FormControl, FormControlLabel } from '@material-ui/core';
import React from 'react'

const CheckBox = (props) => {
    const { name, label, onChange, value, ...others} = props;
    return (
        <FormControl style={{background: "transpanrent"}} >
            <FormControlLabel control={
                <MuiCheckBox
                    name={name}
                    color = "primary"
                    checked = {value}
                    onChange = {onChange}
                    {...others}
                />
            } 
            label = {label}
            
            />
        </FormControl>
    )
}

export default CheckBox
