import Typography from "@material-ui/core/Typography";
import React from "react";

const FigureComponent = ({ figure, title, unit, color }) => {
  return (
    <div>
      <Typography variant="subtitle1">
        <span
          style={{
            fontSize: 14,
            font: "Helvetica",
            color: "#B4B4B3",
            marginRight: "12px",
          }}
        >
          {title}
        </span>
        <span style={{ color: color, fontWeight: "bold" }}>{figure}</span>{" "}
        <span style={{ fontSize: 13, font: "Helvetica", color: color }}>
          {unit}
        </span>
      </Typography>
    </div>
  );
};

export default FigureComponent;
