import React, { useState } from "react";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { COLORS } from "../../Constants.js";
import PageHeader from "../PageHeader/PageHeader.js";
import { useAuth } from "../../Contexts/authContext";
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Badge,
} from "@material-ui/core";
import { useCustomerBalanceContext } from "../../Contexts/customerBalanceContext.js";
import Loading from "../Controls/Loading.jsx";
import Input from "../Controls/Input.js";
import { Search } from "@material-ui/icons";
import Button from "../Controls/Button.js";
import SyncIcon from "@material-ui/icons/Sync";
import InfoIcon from "@material-ui/icons/Info";
import useTable from "../useTable.js";
import ActionButton from "../Controls/ActionButton.js";
import { useCustomerData } from "../../Contexts/customerDataContext.js";

import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: COLORS.ROOT_BACKGROUND,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(3),
    },
  },
  headingWrapper: {
    display: "flex",
    "& .MuiFormControl-root": {
      width: "30%",
      height: "15%",
      margin: theme.spacing(2.5),
      backgroundColor: "#E8F0FE",
      borderRadius: "5px",
    },
  },
  pageContent: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
      backgroundColor: COLORS.NAV_BACKGROUND,
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1.5),
      padding: theme.spacing(1),
      backgroundColor: "transparent",
    },
  },
  badge: {
    color: "#60f3fa",
    marginRight: theme.spacing(2),
  },
  resetButton: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: "12px",
    },
  },
  toolbar: {
    "& .MuiTextField-root": {
      width: "50%",
      margin: theme.spacing(1),
      backgroundColor: "#E8F0FE",
      borderRadius: "5px",
    },
    "& .MuiInputBase-input": {
      padding: "12px 12px 12px 0",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  addButton: {
    color: "#60f3fa",
    marginRight: theme.spacing(2),
    borderTopColor: "#60f3fa",
    borderRightColor: "#60f3fa",
    borderLeftColor: "#60f3fa",
    borderBottomColor: "#60f3fa",
  },
}));

const headCells = [
  { id: "name", label: "Customer Name" },
  { id: "serial", label: "Meter Serial" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "creditBalance", label: "Credit Balance (LRD)" },
  { id: "mode", label: "Meter State" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const CustomerTable = () => {
  const { customerBalanceQuery } = useCustomerBalanceContext();
  const { resetMyMeter, setMeterSerial } = useCustomerData();
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [resetCount, setResetCount] = useState(0);
  const history = useHistory();
  const { path } = useRouteMatch();
  const { TblContent, TblHead, TblPagination, recordsAfterPaging } = useTable(
    headCells,
    customerBalanceQuery,
    filterFn
  );

  const handleSearch = (event) => {
    const target = event.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else {
          return items.filter(
            (x) =>
              x.name.toLowerCase().includes(target.value) ||
              x.serial.includes(target.value)
          );
        }
      },
    });
  };

  const handleMeterReset = async (item) => {
    const { serial } = item;
    await resetMyMeter(serial, {
      onSuccess: () => {
        setResetCount((prevCount) => prevCount + 1);
      },
    });
  };

  const navigateToDetails = (item) => {
    const { serial } = item;
    setMeterSerial(serial);
    history.push(`${path}/customer-details`);
  };

  const resetAllMeters = async () => {
    const { data } = customerBalanceQuery;
    if (!data) return;
    for (const item of data) {
      const { serial } = item;
      await resetMyMeter(serial, {
        onSuccess: () => {
          setResetCount((prevCount) => prevCount + 1);
        },
      });
    }
  };

  return (
    <>
      <PageHeader
        title="Customers List"
        subTitle={`Displays the list of customers with credit balances and and reset button to enable resetting of customer meters.`}
        icon={<PeopleAltIcon />}
      />
      {customerBalanceQuery.status === "loading" ? (
        <Loading />
      ) : (
        <Paper className={classes.pageContent}>
          <Toolbar className={classes.toolbar}>
            <Input
              label="Search"
              name="search"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            {currentUser &&
              (currentUser.role === "Admin" ||
                currentUser.role === "Developer") && (
                <div className={classes.resetButton}>
                  <Button
                    text="Reset All"
                    className={classes.addButton}
                    variant="outlined"
                    size="large"
                    onClick={resetAllMeters}
                    startIcon={<SyncIcon />}
                  />
                  <Badge badgeContent={resetCount} max={9999} color="secondary">
                    <div className={classes.badge}>Reset Count</div>
                  </Badge>
                </div>
              )}
          </Toolbar>
          <TblContent>
            <TblHead />
            <TableBody>
              {recordsAfterPaging().map((item) => (
                <TableRow key={item.serial}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.serial}</TableCell>
                  <TableCell>{item.phoneNumber}</TableCell>
                  <TableCell>{`${item.creditBalance}`}</TableCell>
                  <TableCell>{item.mode === 1 ? "NO" : "AUTO"}</TableCell>
                  <TableCell>
                    {currentUser &&
                      (currentUser.role === "Admin" ||
                        currentUser.role === "Developer") && (
                        <ActionButton
                          color="primary"
                          onClick={() => handleMeterReset(item)}
                        >
                          <SyncIcon fontSize="small" />
                        </ActionButton>
                      )}
                    <ActionButton
                      color="primary"
                      onClick={() => navigateToDetails(item)}
                    >
                      <InfoIcon fontSize="small" />
                    </ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContent>
          <TblPagination />
        </Paper>
      )}
    </>
  );
};

export default CustomerTable;
