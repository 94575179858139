import React from "react";
import Skeleton from "../Controls/Skeleton";
import FigureComponent from "./FigureComponent";
import { useLatestReadingContext } from "../../Contexts/latestReadingContext";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { COLORS } from "../../Constants";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: COLORS.CARD_BACKGROUND,
    width: "98%",
    minHeight: 200,
    borderRadius: 5,
    //display: 'flex',
    padding: 5,
    color: "white",
    justifyContent: "center",
  },
  subheader: {
    color: "#bcaaa4",
  },
  addButton: {
    color: "#60f3fa",
    borderColor: "#60f3fa",
    marginTop: 10,
  },
}));

const getDate = (dateTime) => {
  const date = new Date(dateTime);
  const strDate = date.toUTCString();
  return strDate;
};

const LastReadingFromTotalizers = () => {
  const { lastTotalizerReadingQuery } = useLatestReadingContext();

  const formatNumber = (number, decimals = 1) => {
    if (number === undefined) return 0;
    return number.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const classes = useStyles();

  return (
    <Grid item xs={12} lg={4}>
      {lastTotalizerReadingQuery.status === "loading" ? (
        <Skeleton />
      ) : (
        <Card className={classes.card}>
          <CardHeader
            classes={{ subheader: classes.subheader }}
            subheader="Last Readings from Totalizers"
          />
          <CardContent>
            <FigureComponent
              figure={formatNumber(
                lastTotalizerReadingQuery.data.data.total_true_power_avg / 1000
              )}
              title="Total Load: "
              unit="kW"
              color="#00DFA2"
            />
            {/* Total current */}
            <FigureComponent
              figure={formatNumber(
                lastTotalizerReadingQuery.data.data.total_current_avg
              )}
              title="Total Current:  "
              unit="A"
              color="#00DFA2"
            />
            {/* Line currents */}
            <FigureComponent
              figure={formatNumber(
                lastTotalizerReadingQuery.data.data.L1_current_avg
              )}
              title="L1 Current: "
              unit="A"
              color="#00DFA2"
            />
            <FigureComponent
              figure={formatNumber(
                lastTotalizerReadingQuery.data.data.L2_current_avg
              )}
              title="L2 Current: "
              unit="A"
              color="#00DFA2"
            />
            <FigureComponent
              figure={formatNumber(
                lastTotalizerReadingQuery.data.data.L3_current_avg
              )}
              title="L3 Current: "
              unit="A"
              color="#00DFA2"
            />

            <Typography
              variant="body2"
              style={{ color: "#B4B4B3" }}
              component="h5"
            >
              {`Last Reading Time:  ${getDate(
                lastTotalizerReadingQuery.data.data.datetime
              )} `}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default LastReadingFromTotalizers;
