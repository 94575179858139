import React from 'react';


import ReactApexChart from "react-apexcharts";


const AverageRechargeCurve = ({salesData, totalSalesData}) => {   

    const {totalSale, rechargeCount} = totalSalesData


    const series = [{
        name: 'Avg Recharge Amount',
        data: salesData.map(sd=>
            {
                const {totalSale, rechargeCount} = sd;
                if(!totalSale || !rechargeCount)
                    return 0;
                const avgRecharge = totalSale/rechargeCount;
                return avgRecharge;
            })
    }];
    const labels = salesData.map(sd=>{
        const date = new Date(sd.year, sd.month-1);
        const period = `${date.toLocaleString('default', { month: 'long', year: 'numeric' })}`;
        return period;
    });
    const options = {
        chart: {
            type: 'line',
            height: 280,
            sparkline: {
                enabled: true
            },
        },
        labels: labels,
        stroke: {
            curve: 'smooth',
            //curve: 'straight',
            width:'2'
        },
        colors: ["skyblue"],        
        title: {
            text: `${parseFloat((totalSale/rechargeCount).toFixed(1)).toLocaleString()} LRD`,
            offsetX: 30,
            style: {
                fontSize: '24px',
                color:'white'            
            }
        },
        subtitle: {
            text: 'Average Recharge Amount',
            offsetX: 30,
            style: {
                fontSize: '16px',
                color:'white'
            }
        },
        tooltip:{
            y:{
                formatter:(y)=>{
                    if(y!==undefined)
                        return `${parseFloat(y.toFixed(0)).toLocaleString()} LRD`;
                    return y;
                }
            }
        }
    }
    return (
        <div>
            <ReactApexChart height={280} options={options} series={series} type="line" />
        </div>
    )
}

export default AverageRechargeCurve
