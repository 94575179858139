import { makeStyles } from "@material-ui/core";
import {COLORS, DRAWERWIDTH} from "../../Constants"

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: COLORS.ROOT_BACKGROUND,
        //textAlign: "center",       
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2, 4),
            width:`calc(100vw - ${DRAWERWIDTH+17}px)`,
        },
    },
    card: {
        backgroundColor: COLORS.CARD_BACKGROUND,
        width: '100%',
        minHeight: 200,
        borderRadius: 5,
        //display: 'flex',
        padding:5,
        color:'white',
        justifyContent: 'center',
    },
    subheader: {
        color: "#bcaaa4",
      },
      charts2: {
        background: COLORS.CARD_BACKGROUND,
      },
    mainChartBody: {
        overflowX: "auto",
    },
    mainChartHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.only("xs")]: {
            flexWrap: "wrap",
        },
    },
    mainChartHeaderLabels: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            order: 3,
            width: "100%",
            justifyContent: "center",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
    },
    gridList:{
        flexWrap: 'wrap',
        transform: 'translateZ(0)',
    },
    gridTile:{        
        background:COLORS.CARD_BACKGROUND,
        margin:2,
    },
    mainChartHeaderLabel: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(3),
    },
    mainChartLegentElement: {
        fontSize: "18px !important",
        marginLeft: theme.spacing(1),
    },
    skeletonContainer: {
        height: 0,
        overflow: "hidden",
        paddingTop: "100%",
        position: "relative"
      },
      skeleton: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "90%",
        height: "80%"
      },
}));